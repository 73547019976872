.magnifying_glass_div {
  position: relative;
  top: 16vh;
  left: -1em;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.magnifying_glass {
  display: inline-block;
  border-radius: 50%;
  animation-name: spin;
  animation-duration: 750ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  width: 7em;
  height: 7em;
  border: 0.55em solid var(--light-mode-spinner-color);
  clip-path: circle(82% at 1%);
  border-right: 3em;
  border-left-style: solid;
}

.magnifying_glass_handle {
  position: relative;
  left: 100%;
  top: -1.3em;
  height: 4.3em;
  width: 0.7em;
  background: var(--light-mode-spinner-color);
  transform: rotate(-45deg);
  border-radius: 0.3em;
}

.loading_logo_main_div {
  height: 100vh;
}
