#searcher-main {
  margin-inline: 5%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
#buttons-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 10px;
  column-gap: 10px;
}
#export-button,
#promote-button {
  font-weight: bold;
  font-family: Graphik;
}
#export-button:hover,
#promote-button:hover {
  background-color: var(--dark-mode-primary-button-hover-color);
}
#searcher-main .spinner-border {
  align-self: center;
  left: 0;
  top: 3em;
}
#rows-displayed {
  margin-bottom: 0;
  align-self: center;
}
#searcher-main .custom-select {
  border: 1.5px solid var(--light-mode-button-outline-base-color);
}
#searcher-main #filters {
  display: flex;
  column-gap: 18px;
  margin-left: 2rem;
  padding-inline: 0.4rem;
}

#searcher-main .card-type-icon {
  color: var(--dark-mode-icon-font-color);
  height: 25px;
  width: 25px;
  position: relative;
  border-radius: 50%;
  text-align: center;
  margin-left: -1%;
  margin-top: -1%;
}
#searcher-main .card-type-icon {
  color: var(--light-mode-white-text);
}
#searcher-main .bb-icon {
  background-color: var(--light-mode-bb-icon-color);
}
#searcher-main .tc-icon {
  background-color: var(--light-mode-tc-icon-color);
}
#searcher-main .ctc-icon {
  background-color: var(--light-mode-ctc-icon-color);
}
#searcher-main .ht-icon {
  background-color: var(--light-mode-ht-icon-color);
}
#searcher-main .sy-icon {
  color: white;
  background-color: var(--light-mode-syn-icon-color);
}

ul.results-displayed {
  list-style-type: none;
}
#searcher-main .card {
  display: flex;
  font-family: "Graphik";
  margin: 7px;
  border-color: var(--light-mode-border-color);
}

#searcher-main .reqIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

#searcher-main .card-header {
  width: auto;
  margin-top: 1.5%;
  margin-left: 0.5%;
  font-size: 12px;
  font-weight: bold;
  height: 21px;
  background-color: var(--light-mode-white-color);
  border-bottom: var(--bs-card-border-width) var(--bs-card-border-color);
}
#searcher-main .card-body {
  margin-left: 15px;
}

#searcher-main .card-title {
  cursor: pointer;
  color: var(--light-mode-title-color-blue);
  text-decoration: none;
  font-weight: 700;
  width: fit-content;
}
#searcher-main .card-title:hover {
  color: var(--light-mode-title-color-hover);
}

#searcher-main .card-url {
  font-size: 0.7rem;
}

#searcher-main .card-description {
  font-size: 0.9rem;
  color: var(--light-mode-feedback-color);
}

#searcher-main .card-keyword {
  font-size: 0.8rem;
  font-weight: 700;
  margin-right: 0.25rem;
}

#searcher-main .card-keyword:hover {
  font-size: 0.8rem;
  font-weight: 700;
  margin-right: 0.25rem;
  background-color: var(--light-mode-pills-hover);
}

button.expand-collapse {
  color: var(--purple_01);
  border-color: var(--light-mode-button-outline-base-color);
  font-size: 0.8rem;
  font-weight: 700;
  margin-right: 0.25rem;
}
button.expand-collapse:hover {
  background-color: var(--light-mode-button-outline-bg-color);
}
button.expand-collapse:hover > div > span {
  border-color: var(--light-mode-white-color);
}
#no-results {
  text-align: center;
}

/* arrow common style */
.arrow {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-top: 1.4px solid var(--purple_01);
  border-right: 1.4px solid var(--purple_01);
}

.arrow-top {
  transform: rotate(-45deg);
}
.arrow-bottom {
  transform: rotate(135deg);
}

.searcher-arrows-flex {
  display: flex;
  flex-direction: column;
  height: 1.5em;
  justify-content: center;
}

.arrow-top.collapse-icon-position {
  position: relative;
  top: 0.2em;
}

.arrow-bottom.collapse-icon-position {
  position: relative;
  bottom: 0.2em;
  left: 0.06em;
}

/* DARK MODE */

.darkMode.arrow.arrow-top,
.darkMode.arrow.arrow-bottom {
  border-color: var(--purple_01);
  min-height: 0vh;
}

button.expand-collapse:hover > div > .darkMode.arrow.arrow-top {
  background-color: var(--light-mode-button-outline-bg-color);
  border-color: var(--dark-mode-dark-text);
}
button.expand-collapse:hover > div > .darkMode.arrow.arrow-bottom {
  background-color: var(--light-mode-button-outline-bg-color);
  border-color: var(--dark-mode-dark-text);
}

.darkMode #searcher-main .card-header {
  background-color: var(--dark-mode-background-color);
}

.darkMode #searcher-main .searcher-card {
  background-color: var(--dark-mode-background-color);
}

.darkMode #searcher-main .card-title {
  color: var(--dark-mode-title-color-blue);
}
.darkMode #searcher-main .card-title:hover {
  color: var(--dark-mode-title-color-hover);
}

.darkMode #searcher-main .card-description {
  color: var(--dark-mode-feedback-color);
}
.darkMode #searcher-main .bb-icon {
  background-color: var(--dark-mode-bb-icon-color);
}
.darkMode #searcher-main .tc-icon {
  background-color: var(--dark-mode-tc-icon-color);
}
.darkMode #searcher-main .ctc-icon {
  background-color: var(--dark-mode-ctc-icon-color);
}
.darkMode #searcher-main .ht-icon {
  background-color: var(--dark-mode-ht-icon-color);
}
.darkMode #searcher-main .sy-icon {
  color: black;
  background-color: var(--dark-mode-syn-icon-color);
}

.darkMode #no-results {
  color: var(--dark-mode-text-color);
}
