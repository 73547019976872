.home-menu-div {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  width: var(--sidemenu-width);
  top: 0em;
  transition: width 0.1s ease-in;
  width: 16.5em;
  margin-left: -4px;
}
.home-menu-div::-webkit-scrollbar {
  width: 0.3em;
}

.home-menu-div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.home-menu-div::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.home-menu-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.home-opt-li-label {
  font-size: 15px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  top: 7px;
}

.home-menu-separator {
  height: 1.2px;
  background-color: var(--light-mode-icon-color);
  margin-left: 16px;
  margin-bottom: 0.3em;
  margin-top: 0.3em;
}

.section-icon-class {
  fill: var(--light-mode-icon-color);
}

.opt-icon-class {
  width: 1.4em;
  stroke: var(--light-mode-icon-color);
  margin-right: 7px;
}
.opt-icon-class-v2 {
  width: 1.4em;
  fill: var(--light-mode-icon-color);
  margin-right: 7px;
}

.opt-icon-class-v2 > path {
  stroke: var(--light-mode-icon-color);
}

.opt-icon-class-home > path {
  stroke: var(--light-mode-icon-color);
}
.opt-icon-gear > path {
  stroke: var(--light-mode-icon-color);
}

.home-opt-action-btn {
  background-color: transparent;
  border: none;
  margin: 7px;
  overflow: hidden;
}

.home-opt-url-btn {
  margin-left: 7px;
}
.home-menu-ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 11px;
  width: 16em;
}

.hide-toggle-btns {
  display: none !important;
}

.hide-div {
  width: 0px;
}

.change-menu-btn-size {
  animation: hideBtnAnimation 0.5s forwards;
}

.change-menu-labels-visibility {
  opacity: 0;
}

.add-li-width {
  width: 4em;
}

/* menu adjustments */
.home-menu-option-li-hover-effect:hover {
  background: var(--light-mode-home-btnbox-bg-color-hover);
  margin-left: 5px;
}

.home-menu-option-li-hover-effect:hover button > svg.opt-icon-class,
.home-menu-option-li-hover-effect:hover button > svg.opt-icon-class-v2 {
  position: relative;
  right: 3px;
}
.home-menu-option-li-hover-effect:hover button > svg.opt-icon-class {
  stroke: var(--light-mode-text-color);
}
.home-menu-option-li-hover-effect:hover button > svg.opt-icon-class > path {
  stroke: var(--light-mode-text-color);
}

.home-menu-option-li-hover-effect:hover button > svg.opt-icon-class-v2 {
  fill: var(--light-mode-text-color);
}
.home-menu-option-li-hover-effect:hover button > svg.opt-icon-class-v2 > path {
  stroke: var(--light-mode-text-color);
}

.active-home-menu-option {
  background: var(--light-mode-icon-color);
}

.active-home-menu-option > button > svg.opt-icon-class {
  stroke: var(--light-mode-white-color);
  position: relative;
}

.active-home-menu-option > button > svg.opt-icon-class > path {
  stroke: var(--light-mode-white-color);
}
.active-home-menu-option > button > svg.opt-icon-class-v2 {
  fill: var(--light-mode-white-color);
  position: relative;
}
.home-menu-option-li {
  display: flex;
  align-items: center;
  height: 2.5em;
}
.active-home-menu-option > button > label {
  color: var(--light-mode-white-color);
}

.contract-home-menu-by {
  width: var(--contracted-sidemenu-width);
}

.home-menu-div ~ .home-menu-outlet-container {
  width: calc(100% - var(--sidemenu-width));
}

.home-menu-outlet-container {
  transition: width 0.1s ease-in;
  overflow-y: scroll;
}
.contract-home-menu-by ~ .home-menu-outlet-container {
  width: calc(100% - var(--contracted-sidemenu-width));
  overflow-y: scroll;
}
.contract-home-menu-by ~ .double-arrow-menu-div {
  left: calc(var(--contracted-sidemenu-width) + -9px);
}
.double-arrow-menu-div {
  transition: left 0.1s ease;
}

/* menu adjustments */
.darkMode .home-menu-option-li-hover-effect:hover {
  background: var(--light-mode-home-btnbox-bg-color-hover);
  margin-left: 2px;
}

.darkMode .home-menu-option-li-hover-effect:hover button > svg.opt-icon-class,
.darkMode
  .home-menu-option-li-hover-effect:hover
  button
  > svg.opt-icon-class-v2 {
  position: relative;
  right: 1px;
}
.darkMode .home-menu-option-li-hover-effect:hover button > svg.opt-icon-class {
  stroke: var(--light-mode-text-color);
}
.darkMode
  .home-menu-option-li-hover-effect:hover
  button
  > svg.opt-icon-class
  > path {
  stroke: var(--light-mode-text-color);
}

.darkMode
  .home-menu-option-li-hover-effect:hover
  button
  > svg.opt-icon-class-v2 {
  fill: var(--light-mode-text-color);
}
.darkMode
  .home-menu-option-li-hover-effect:hover
  button
  > svg.opt-icon-class-v2
  > path {
  stroke: var(--light-mode-text-color);
  right: 2px;
}
.darkMode .home-menu-option-li-hover-effect:hover button > label {
  color: var(--dark-mode-dark-text);
}

.darkMode .active-home-menu-option {
  background: var(--light-mode-icon-color);
}

.darkMode .active-home-menu-option > button > svg.opt-icon-class > path {
  stroke: var(--light-mode-white-color);
}
.darkMode .active-home-menu-option > button > svg.opt-icon-class-v2 {
  fill: var(--light-mode-white-color);
  position: relative;
  right: 2px;
}

.darkMode .active-home-menu-option > button > label {
  color: var(--light-mode-white-color);
}
