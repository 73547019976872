.home_page {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 11vh;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
}
.welcome_msg {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  width: fit-content;
}

.background_img {
  opacity: 0.3;
  position: fixed;
  bottom: calc((1vh + 1vw) / (2 * 0.7));
  right: 15.5vw;
  z-index: -1;
  width: 65vh;
  height: 12vw;
  min-height: 40vh;
}

.home_page #MainLogos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: nowrap;
  width: max-content;
}

.home_page #MainLogos .acn-logo {
  width: 6vw;
  height: 6vw;
}

.home_page #MainLogos .searchtools-logo {
  margin-top: 7px;
  width: 18vw;
  height: 7vw;
  background-size: cover;
}

.adjust-home-btn {
  border-radius: 50rem;
  height: 50px;
}

#welcome_message {
  margin: 10px 12% 7px 12%;
  font-size: 15px;
  font-family: "Graphik";
  text-align: left;
}

.darkMode .home_page {
  color: var(--dark-mode-text-color);
}
