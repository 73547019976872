.cross {
  color: var(--light-mode-red-01-color);
}
.message {
  color: var(--light-mode-text-color);
}
.page-not-found {
  text-align-last: center;
  padding: 160px;
}
/* Dark Mode */
.darkMode .message {
  color: var(--dark-mode-text-color);
}
.darkMode .cross {
  color: var(--dark-mode-red-01-color);
}
