.content-reports {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  font-family: Graphik;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 150px;
}
.report-head {
  color: var(--light-mode-text-color);
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  justify-content: center;
}
.darkMode .report-head {
  color: var(--dark-mode-text-color);
}
.select-source-title {
  margin-top: 3%;
}
.reports-options {
  width: 396px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  text-align: -webkit-left;
  color: var(--light-mode-text-color);
  margin-left: 93px;
}
.darkMode .reports-options {
  color: var(--dark-mode-text-color);
}

.form-check-input:checked {
  background-color: var(--light-mode-button-outline-base-color);
  border-color: var(--light-mode-border-color);
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(142, 97, 232, 0.25);
}
.darkMode .form-check-input:checked {
  background-color: var(--dark-mode-button-outline-base-color);
  border-color: var(--dark-mode-border-color);
}
.darkMode .form-check-input {
  background-color: var(--dark-mode-background-color);
  border-color: var(--dark-mode-border-color);
}
.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid var(--light-mode-border-color);
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.search-term {
  margin-top: 10px;
  width: 490px;
}

.pills-group-content-searcher {
  display: inline-flex;
  flex-flow: wrap;
  margin-top: 1em;
}

.plus-icon-search-term {
  width: 24px;
  height: 22px;
  display: flex;
  justify-content: center;
  margin-top: -30px;
  margin-left: 500px;
  color: var(--light-mode-icon-color);
}
.plus-icon-search-term:hover {
  box-shadow: 0 0 0 0.2rem var(--light-mode-box-shadow-color);
}
.download-report {
  margin-top: 35px;
  display: flex;
  justify-content: center;
}

button#download-report:active {
  color: var(--light-mode-dropdown-item-text);
  background-color: var(--light-mode-active-btn-primary-forms);
  border-color: var(--light-mode-active-btn-primary-forms);
}

.report-download-spinner {
  flex-flow: column;
  align-items: center;
}

.creating-report-message {
  font-size: x-large;
}
.darkMode .creating-report-message {
  color: var(--dark-mode-text-color);
}

.loading-report-div {
  position: relative;
  height: 14vw;
  top: 13em;
}

.margin-div {
  position: relative;
  height: 2em;
}

.loading-report-overlay {
  position: absolute;
  bottom: 1em;
  right: -14em;
  height: 55em;
  width: 115vw;
  background-color: rgb(0 0 0 / 20%);
  z-index: 3;
}

.darkMode .loading-report-overlay {
  width: 122vw;
  background-color: rgb(0 0 0 / 45%);
}
