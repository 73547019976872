#bestbet-synonymsContainer {
  width: var(--bestbet-field-width);
  align-self: center;
  display: flex;
  flex-wrap: wrap;
}
#howto-synonymsContainer {
  display: flex;
  flex-direction: column;
}
.synonyms-box-title {
  position: relative;
  top: 1px;
  margin-left: 5px;
}
.spinner-synonyms-ht {
  position: relative;
  width: 1rem !important;
  height: 1rem !important;
  margin-left: 5px;
  margin-bottom: 3px;
}
#synonyms-box {
  color: var(--light-mode-text-color);
  background-color: var(--light-mode-light-grey-color-3);
  padding-left: 5px;
  margin-bottom: 15px;
  height: auto;
  display: flex;
  flex-flow: wrap;
  padding: 15px;
}

.synonymx-box-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
}
.label-tooltip {
  position: relative;
  width: 20px;
  height: 20px;
}

.darkMode .synonyms-box-title {
  color: var(--dark-mode-text-color);
}
.darkMode #synonyms-box {
  color: var(--dark-mode-text-color);
  background-color: var(--dark-mode-synonyms-box);
  border: 1px solid var(--dark-mode-modal-border);
}

.darkMode .spinner-synonyms-ht {
  color: var(--dark-mode-text-color);
}
