.promote_page {
  box-sizing: border-box;
  position: relative;
  margin-top: 15px;
}

.menu_links {
  display: flex;
  flex-direction: row;
  margin: 10px 15% 10px 15%;
  justify-content: center;
  align-items: center;
}

.menu_links .nav-link {
  font-family: "Graphik";
  color: var(--light-mode-promote-nav-links);
  font-size: 16px;
}

#bb_link {
  border-style: none;
  border-bottom: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}
#bb_link.active {
  color: var(--light-mode-text-color);
  border-bottom-color: var(--light-mode-bb-icon-color);
}

#tc_link {
  border-style: none;
  border-bottom: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}
#tc_link.active {
  color: var(--light-mode-text-color);
  border-bottom-color: var(--light-mode-tc-icon-color);
}

#howto_link {
  border-style: none;
  border-bottom: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}
#howto_link.active {
  color: var(--light-mode-text-color);
  border-bottom-color: var(--light-mode-ht-icon-color);
}

#ctc_link {
  border-style: none;
  border-bottom: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}
#ctc_link.active {
  color: var(--light-mode-text-color);
  border-bottom-color: var(--light-mode-ctc-icon-color);
}

#synonyms_link {
  border-style: none;
  border-bottom: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}
#synonyms_link.active {
  color: var(--light-mode-text-color);
  border-bottom-color: var(--light-mode-syn-icon-color);
}

.darkMode .nav-link {
  color: var(--dark-mode-links-inactive-promote);
}

.darkMode #bb_link.active {
  color: var(--dark-mode-text-color);
  border-bottom-color: var(--dark-mode-bb-icon-color);
}

.darkMode #tc_link.active {
  color: var(--dark-mode-text-color);
  border-bottom-color: var(--dark-mode-tc-icon-color);
}

.darkMode #howto_link.active {
  color: var(--dark-mode-text-color);
  border-bottom-color: var(--dark-mode-ht-icon-color);
}

.darkMode #synonyms_link.active {
  color: var(--dark-mode-text-color);
  border-bottom-color: var(--dark-mode-synonyms-form-color);
}

.darkMode #ctc_link.active {
  color: var(--dark-mode-text-color);
  border-bottom-color: var(--dark-mode-ctc-icon-color);
}
