.paginationContainer {
  display: flex;
  justify-content: center;
  position: sticky;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 1000;
}

.page-item {
  background-color: var(--light-mode-white-color);
}
.page_btn {
  color: var(--light-mode-link-color-normal);
  background-color: transparent;
  border: 1px solid #00000021;
  width: 2.3em;
  height: 2.3em;
}

.page_btn:hover {
  color: var(--light-mode-link-color-normal);
  background-color: var(--light-mode-light-grey-color-3);
  border: 1px solid #00000021;
  width: 2.3em;
  height: 2.3em;
}
.page_btn_active {
  background-color: var(--light-mode-feedback-purple);
  color: var(--dark-mode-text-color);
}
.page_btn .disabled {
  color: var(--dark-mode-text-color);
  background-color: aqua;
}
.arrow_btn,
.arrow_btn:hover {
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-bg-disabled-pagination-btns) !important;
  border: 1px solid #00000021;
  width: 2.3em;
  height: 2.3em;
  cursor: default;
}
.darkMode .page_btn {
  color: var(--dark-mode-link-color-normal);
  background-color: var(--dark-mode-page-nav-color);
  border: 1px solid var(--dark-mode-page-nav-color);
  width: 2.3em;
  height: 2.3em;
}
.darkMode .arrow_btn,
.darkMode .arrow_btn {
  background-color: var(--dark-mode-bg-disabled-pagination-btns) !important;
}
.darkMode .page_btn_active {
  color: var(--dark-mode-text-color);
  background-color: var(--dark-mode-link-color-visited);
}
.darkMode .page_btn:hover {
  color: var(--dark-mode-text-color);
  background-color: var(--dark-mode-btn-info-color);
  border: 1px solid var(--dark-mode-btn-info-color);
  width: 2.3em;
  height: 2.3em;
}

.darkMode .arrow_btn,
.darkMode .arrow_btn {
  color: var(--dark-mode-text-color);
  background-color: var(--dark-mode-page-nav-color);
  border: 1px solid var(--dark-mode-page-nav-color);
  width: 2.3em;
  height: 2.3em;
  cursor: default;
}

.page_item_li_active {
  background-color: var(--light-mode-feedback-purple);
  color: var(--dark-mode-text-color);
}
.darkMode .page_item_li_dark_active {
  color: var(--dark-mode-text-color);
  background-color: var(--dark-mode-link-color-visited);
}

@media (max-width: 1024px) {
  .paginationContainer {
    bottom: 2.5em;
  }
}
