.manage_my_requests {
  display: flex;
  flex-flow: column;
  padding-bottom: 120px;
  margin-top: 15px;
  margin-left: 4em;
  margin-right: 4em;
}

.manage_banner_styles {
  background-color: var(--light-mode-banner-color);
  height: 60px;
}

.manage_banner_title {
  display: flex;
  justify-content: center;
  font-family: "Graphik";
  font-size: 16px;
}

.manage_select_group {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-family: "Graphik";
}
.manage_select_group .select_class {
  width: 270px;
  margin: 10px;
}
.cards_box {
  display: flex;
  margin-top: 10px;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
}

.manage_card {
  display: flex;
  font-family: "Graphik";
  height: 250px;
  width: 360px;
  margin: 5px;
  border-color: var(--light-mode-border-color);
}
.card-header span {
  color: var(--light-mode-white-text);
}
.card-body {
  margin-left: 15px;
  margin-right: 15px;
}
.manage_my_requests .card-footer {
  font-family: "Graphik";
  font-size: 12px;
  height: 58px;
  background-color: var(--light-mode-footer-bg-color);
  height: 54px;
  display: flex;
  background-color: var(--light-mode-footer-bg-color);
  justify-content: space-between;
  align-items: center;
}
.footer_date {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 3px;
}
.footer_state {
  display: flex;
  margin-bottom: 3px;
}
.card .card-title {
  font-family: "Graphik";
  color: var(--light-mode-title-color-blue);
  font-weight: bold;
  margin-bottom: 2px;
  margin-top: -13px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}

#sub_url_card {
  line-height: 19px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  margin-bottom: 16px;
}
.card .card-title:hover {
  color: var(--light-mode-link-color-hover);
  text-decoration: underline;
  cursor: pointer;
}
.card .card-text {
  margin-top: 7px;
  font-size: 13px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}
.card-header {
  height: 20px;
  border-style: none;
  background-color: transparent;
}
.icon_link {
  text-decoration-style: none;
}
.request_icons {
  border-radius: 50%;
  font-size: 13px;
  height: 25px;
  width: 25px;
}
.card-header .bb {
  background-color: var(--light-mode-bb-manage-icon);
}
.type_request_icon {
  margin-top: -10px;
}
.card-header {
  margin-left: -4px;
  font-size: 8px;
  font-weight: bold;
  height: 10px;
}
.card-header:first-child {
  font-size: 12px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  margin-left: 4px;
  margin-top: 4px;
  font-style: none;
  font-weight: bold;
  border-style: none;
}
.type_req_icon {
  margin-left: -7px;
}
.bbIcon {
  content: "BB";
  background-color: var(--light-mode-bb-icon-color);
}
.tcIcon {
  content: "TC";
  background-color: var(--light-mode-tc-icon-color);
}
.ctcIcon {
  background-color: var(--light-mode-ctc-icon-color);
}
.htIcon {
  content: "HT";
  background-color: var(--light-mode-ht-icon-color);
}
.synIcon {
  content: "SY";
  background-color: var(--light-mode-syn-icon-color);
}
.ctcIcon span {
  position: absolute;
  left: 15px;
}
.request-title:focus {
  margin-left: 2px !important;
}
.edit_button {
  display: flex;
  width: 75px;
  height: 38px;
  border-radius: 0.25rem;
  align-items: center;
  justify-content: center;
  background-color: var(--light-mode-btn-info-color);
  border-color: var(--light-mode-btn-info-color);
  border-style: none;
  font-family: "Graphik";
  color: var(--light-mode-white-text);
  font-weight: bold;
  font-size: 13px;
}
.edit_button:focus {
  border-color: var(--light-mode-btn-info-shadow);
  box-shadow: 0 0 0 0.2rem var(--light-mode-btn-info-shadow);
}
.edit_button:hover {
  background-color: var(--light-mode-link-color-hover);
  border-color: var(--light-mode-link-color-hover);
}
.mng_no_rqsts_msg {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-family: "Graphik";
  margin-top: 20px;
}
.btn_redirect_promote {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-mode-btn-info-color);
  border-color: var(--light-mode-btn-info-color);
  font-weight: 700;
  border-radius: 0.25rem;
  color: var(--light-mode-white-text);
  height: 39px;
  width: 185px;
  border-style: none;
  margin-top: 20px;
}
.btn_redirect_promote:hover {
  background-color: var(--light-mode-link-color-hover);
  border-color: var(--light-mode-link-color-hover);
  color: var(--light-mode-white-text);
}
.btn_redirect_promote:focus {
  box-shadow: 0 0 0 0.2rem var(--light-mode-btn-info-shadow);
}

.filter_no_req {
  font-family: "Graphik";
  margin: 20px 505px;
}

.edit_pencil {
  margin-left: 5px;
}

.mmr-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20em;
}

.more-opaque-overlay {
  background-color: rgba(0, 0, 0, 0.641) !important;
}

.darkMode .more-opaque-overlay {
  background-color: rgba(255, 255, 255, 0.54) !important;
}

.darkMode .mng_no_rqsts_msg {
  color: var(--dark-mode-text-color);
}
.darkMode .card {
  background-color: var(--dark-mode-background-color);
  color: var(--dark-mode-text-color);
}
.darkMode .card-header span {
  color: var(--dark-mode-dark-text);
}
.darkMode .card-footer {
  background-color: var(--dark-mode-footer-bg-color);
}
.darkMode .bbIcon {
  background-color: var(--dark-mode-bb-icon-color);
  color: var(--dark-mode-icon-font-color);
}
.darkMode .msg_no_rqsts {
  color: var(--dark-mode-text-color);
}
.darkMode .tcIcon {
  background-color: var(--dark-mode-tc-icon-color);
  color: var(--dark-mode-icon-font-color);
}
.darkMode .ctcIcon {
  background-color: var(--dark-mode-ctc-icon-color);
}
.darkMode .ctcIcon span {
  position: absolute;
  left: 15px;
}
.darkMode .card .card-title {
  color: var(--dark-mode-link-color-normal);
}
.darkMode .card .card-title:hover {
  color: var(--dark-mode-link-color-hover);
  text-decoration: underline;
}
.darkMode .edit_button:hover {
  background-color: var(--dark-mode-home-btnbox-bg-color-hover);
  border-style: solid;
  font-family: "Graphik";
}
.darkMode .filter_no_req {
  color: var(--dark-mode-text-color);
}
.darkMode .htIcon {
  background-color: var(--dark-mode-ht-icon-color);
}

.darkMode .synIcon {
  background-color: var(--dark-mode-syn-icon-color);
}
