// Override default variables before the import

// this are the approved colors from the pallette
$blue_01: #0041f0;
$green_01: #64ff50;
$red_01: #ff3246;
$purple_01: #a100ff;
$purple_02: #7500c0;
$outline_white_color: #e6e6dc; //for buttons like preview and save changes

// input element changed values
$input-border-color: #a100ff;
$input-focus-border-color: #a100ff;
$input-focus-box-shadow: 0 0 0 0.25rem rgb(161 0 255 / 25%);
$input-box-shadow: 0 0 0 0.25rem rgb(161 0 255 / 25%);
$bs-btn-hover-bg: $purple_01;

// select element changed values
$form-select-focus-box-shadow: 0px 0px 9px 0px $purple_01;

//validation colors
$form-feedback-valid-color: $green_01;
$form-feedback-invalid-color: $red_01;
// background color for pagination active page (light mode)
$pagination-active-bg: $purple_01;

// set in Bootstrap’s $theme-colors map:
$theme-colors: (
  "primary": $purple_02,
  "success": $green_01,
  "danger": $red_01,
  "light-mode-purple": $purple_01,
  "dark-mode-purple": $purple_02,
  "dark-mode-outline_white": $outline_white_color
);

.darkMode .table {
  background-color: transparent !important;
  border: var(--dark-mode-text-color);
}

.table > :not(caption) > * > * {
  color: var(--light-mode-text-color);
  background-color: transparent !important;
}
.darkMode .table > :not(caption) > * > * {
  color: var(--dark-mode-text-color);
  background-color: transparent !important;
}
.darkMode thead {
  background-color: #212529;
}
thead {
  background-color: #e2e3e5;
}

.btn.btn-dark-mode-purple {
  color: var(--dark-mode-text-color);
  font-weight: bold;
}
.btn.btn-dark-mode-purple:hover,
.btn.btn-dark-mode-purple:active {
  background-color: var(--dark-mode-home-btnbox-bg-color-hover);
  color: var(--dark-mode-text-color);
}

// $customcolor: #a100ff;

// // set in Bootstrap’s $theme-colors map:
// $theme-colors: (
//   "primary": $primary,
//   "danger": $danger,
//   "customcolor" :$customcolor
// );

// now bg-customcolor, btn-customcolor, etc.. can be called
// (read bootstrap docs for more info)

// React Date Picker
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  border-radius: 0.3rem;
  background-color: var(--light-mode-header-box-shadow);
  color: var(--light-mode-white-text) !important;
  font-family: "Graphik";
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  color: var(--light-mode-white-text) !important;
  background-color: var(--light-mode-header-box-shadow) !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #a055f5b5;
  color: rgb(0, 0, 0);
}

.react-datepicker__header {
  text-align: center;
  background-color: transparent !important;
  border-bottom: none !important;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
  font-family: "Graphik";
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: var(--light-mode-white-text);
  font-family: "Graphik";
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--light-mode-text-color);
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: var(--light-mode-header-box-shadow);
  // background-color: var(--light-mode-white-text);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: var(--light-mode-header-box-shadow) !important;
  color: var(--light-mode-white-text) !important;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: var(--light-mode-header-box-shadow) !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: var(--light-mode-home-btnbox-bg-color-hover) !important;
  color: rgb(0, 0, 0);
}

/*  Dark Mode*/
.darkMode .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--dark-mode-text-color);
}
.darkMode .react-datepicker__day--keyboard-selected, .darkMode .react-datepicker__month-text--keyboard-selected, .darkMode .react-datepicker__quarter-text--keyboard-selected, .darkMode .react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: var( --dark-mode-home-btnbox-bg-color-hover) !important;
  color: rgb(0, 0, 0);
}
.darkMode .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color:var(--dark-mode-modal-background-color) !important;;
}
.darkMode .react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: var(--dark-mode-btn-info-shadow);
}

.darkMode .react-datepicker__header {
  background-color: var(--dark-mode-modal-background-color);
  color: var(--dark-mode-text-color);
  font-family: "Graphik";
}
.darkMode .react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: var(--dark-mode-modal-background-color);
  color: var(--dark-mode-text-color);
  border: 1px solid #aeaeae;
}

.darkMode .react-datepicker__year-dropdown,
.darkMode .react-datepicker__month-dropdown,
.darkMode .react-datepicker__month-year-dropdown {
  background-color: var(--dark-mode-modal-background-color);
  color: var(--dark-mode-text-color);
}

.darkMode .react-datepicker__day {
  color: var(--dark-mode-text-color);
}
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
