.card.comment {
  display: flex;
  font-family: "Graphik";
  border-color: var(--light-mode-border-color);
  justify-content: center;
  width: var(--bestbet-field-width);
  font-size: 14px;
  position: relative;
  left: 0em;
  padding: 1.25rem;
}
.card.comment-tc,
.card.comment-ht {
  display: flex;
  font-family: "Graphik";
  border-color: var(--light-mode-border-color);
  justify-content: center;
  width: 100%;
  font-size: 14px;
  position: relative;
  left: 0em;
  padding: 1.25rem;
}
.comment-tracker {
  display: flex;
  justify-content: center;
  min-height: calc(1.5em + 0.75rem + 70px);
}
.ch-rqst-cmt-user {
  font-size: 14px;
  font-weight: bold;
  color: var(--light-mode-feedback-purple);
}
.ch-rqst-cmt-date {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  margin-left: 5px;
  white-space: nowrap;
  color: var(--light-comment-tracker-date);
}
.ch-rqst-cmt-comment {
  margin-top: 10px;
  font-size: 14px;
  color: var(--light-mode-text-color);
  white-space: pre-line;
}
/* Dark Mode */
.darkMode .card.comment {
  border-color: var(--dark-mode-border-color);
}
.darkMode .ch-rqst-cmt-user {
  color: var(--dark-mode-title-color-purple);
}
.darkMode .ch-rqst-cmt-date {
  color: var(--dark-mode-text-color);
}
.darkMode .ch-rqst-cmt-comment {
  color: var(--dark-mode-text-color);
}
