/* Feedback bubble */
#feedback-bubble {
  display: flex;
  align-items: center;
  position: fixed;
  right: 2vw;
  bottom: 10vh;
}

#feedback-bubble :nth-child(2) {
  background-color: var(--light-mode-feedback-purple);
  color: var(--light-mode-white-color);
  font-weight: bold;
  font-size: small;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--light-mode-feedback-purple);
  border-radius: 7%;
  min-height: 30px;
  min-width: 85px;
}

#open-feedback-dialog-btn:focus {
  box-shadow: 0px 1px 4px 2px #000000;
}

.fa-commenting-o {
  color: var(--light-mode-white-color);
  margin-right: 3%;
}

.close-bubble-btn svg {
  font-size: smaller;
  margin-bottom: 43px;
  margin-right: -5px;
  color: var(--light-mode-feedback-purple);
}

/* Feedback box */
#feedback-box {
  background-color: var(--light-mode-white-color);
  position: fixed;
  right: 3vw;
  bottom: calc(15vh - 50px);
  border: 2px solid var(--light-mode-border-color);
  max-width: 310px;
  z-index: 1;
  width: var(--bestbet-field-width);
  min-width: min-content;
}

.feedback-box-centered {
  background-color: var(--light-mode-white-color);
  position: fixed;
  bottom: 31vh;
  border: 2px solid var(--light-mode-border-color);
  max-width: 310px;
  z-index: 1;
  width: var(--bestbet-field-width);
  min-width: min-content;
}

.feedback-box-body {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-inline: 15px;
}

.feedback-header {
  text-align: center;
  align-items: center;
  background-color: var(--light-mode-feedback-purple);
  color: var(--light-mode-white-color);
  padding: 5px;
  display: flex;
  justify-content: space-around;
  font: inherit;
  height: fit-content;
}

.feedback-header-body {
  font-size: 12px;
  font-weight: bold;
}

.no-post-on-viva-separator {
  width: 3em;
  height: 2em;
}

.hide_feedback_bubble {
  right: 3000vw !important;
}

.close-feedback-box-btn {
  background-color: var(--light-mode-feedback-purple);
  color: var(--light-mode-white-color);
}

.fa-close {
  font-size: 80%;
}

.rating-row {
  display: flex;
  justify-content: space-around;
  padding: 5%;
}

.rating-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.rating-logo svg {
  margin: 5px;
  font-size: 35px;
  color: var(--unselected-feedback-rate);
}

.thumbs-up:hover,
.thumbs-up.selected {
  color: var(--light-mode-green-feedback-rate);
}

.thumbs-down:hover,
.thumbs-down.selected {
  color: var(--light-mode-red-feedback-rate);
}

.thumbs-down {
  transform: rotateY(180deg);
}

#feedback-box .form-group {
  border: none;
  border-radius: 0;
}

.form-group.feedback-msg textarea {
  height: 15vh;
  border-radius: 0;
  transition: none;
  border: 1.9px solid var(--light-mode-feedback-textbox-color);
}

.form-group.feedback-msg textarea:focus {
  box-shadow: none;
}

.feedback-msg textarea.is-invalid {
  border-color: var(--dark-mode-red-01-color);
}

.char-counter,
.post-on-ve {
  color: var(--light-mode-feedback-textbox-color);
}

.invalid-feedback-text {
  font-size: small;
  font-style: italic;
  color: var(--dark-mode-red-01-color);
}

.form-group.feedback-msg textarea::placeholder {
  font-style: italic;
  font-size: small;
}

.viva-engage-check {
  display: flex;
  font-size: 0.7em;
  height: 35px;
}

.viva-engage-check input[type="checkbox"] {
  margin-right: 0%;
  opacity: 1;
  height: fit-content;
  margin-top: 1%;
  margin-right: 2%;
}

.post-on-ve {
  align-items: center;
}

.feedback-buttons {
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
}

.feedback-buttons button {
  font-size: small;
  border-radius: 0%;
  border: 1.9px solid var(--light-mode-button-outline-base-color);
  font-weight: 500;
  color: var(--light-mode-button-outline-base-color);
  background-color: var(--light-mode-white-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 44%;
  height: 30px;
}

.feedback-buttons :first-child {
  background-color: var(--light-mode-feedback-purple);
  color: var(--light-mode-white-color);
}

.feedback-buttons :first-child:disabled {
  background-color: var(--dark-mode-feedback-textbox-color);
  border: none;
}

/* Submitted modal */
.feedback-submitted {
  position: fixed;
  right: 3vw;
  bottom: calc(20vh - 50px);
  border: 2px solid var(--light-mode-border-color);
  max-width: 310px;
  min-width: 100px;
  width: var(--bestbet-field-width);
}

.feedback-submitted .feedback-header {
  justify-content: center;
  font-size: large;
  height: 40px;
}

.feedback-header .searchicon {
  cursor: unset;
  margin-top: 0;
}

.feedback-submitted-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-mode-white-color);
  padding-top: 15px;
  text-align: center;
}

.check-icon {
  background-color: var(--light-mode-white-color);
  border: 2px solid var(--light-mode-icon-color);
  color: var(--light-mode-icon-color);
  height: 60px;
  width: 60px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: xx-large;
}

.feedback-submitted-body p {
  margin-top: 2px;
  font-weight: bold;
}
.comment-icon {
  margin-right: 3px;
}
input#feedback-checkbox {
  border: 1px solid var(--light-mode-text-color);
  opacity: 1;
  border-radius: 0em;
  height: 15px;
  width: 15px;
  margin-top: 5px;
}
.follow-me-viva-engage-msg {
  margin: 5px -5px 8px;
  font-style: italic;
  outline: none;
  font-size: 0.7em;
}
/* Shared */
.close-btn {
  border: none;
  position: relative;
  background-color: transparent;
}

/* DARK MODE */
.darkMode .feedback-box-body {
  background-color: var(--dark-mode-background-color);
  color: var(--dark-mode-feedback-text-color);
}
.darkMode .thumbs-up:hover,
.darkMode .thumbs-up.selected {
  color: var(--dark-mode-green-feedback-rate);
}

.darkMode .thumbs-down:hover,
.darkMode .thumbs-down.selected {
  color: var(--dark-mode-red-feedback-rate);
}

.darkMode .feedback-msg textarea {
  border: 1.9px solid var(--dark-mode-feedback-textbox-color);
  outline-color: var(--dark-mode-feedback-textbox-color);
  background-color: var(--dark-mode-modal-background-color);
  color: var(--dark-mode-feedback-text-color);
}

.darkMode .form-group.feedback-msg textarea::placeholder {
  color: var(--dark-mode-feedback-text-color);
}

.darkMode .char-counter,
.darkMode .post-on-ve {
  color: var(--dark-mode-feedback-textbox-color);
}

.darkMode .feedback-buttons :last-child {
  color: var(--dark-mode-feedback-text-color);
  background-color: var(--dark-mode-modal-background-color);
}

.darkMode .feedback-submitted-body {
  background-color: var(--dark-mode-background-color);
  color: var(--dark-mode-feedback-text-color);
}

.darkMode .check-icon {
  background-color: var(--dark-mode-background-color);
}
.darkMode input#feedback-checkbox {
  border: 1px solid var(--dark-mode-text-color);
}
