.announcement-modal {
  background-color: var(--light-mode-white-color);
  position: absolute;
  left: 17px;
  top: 20px;
  border-radius: 8px;
  padding-bottom: 10px;
  margin: 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  width: 25vw;
  min-width: min-content;
  border: 2px solid var(--light-mode-grey-border-color);
}

.announcement-modal-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: var(--modal-purple-header);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  padding-inline: 1vw;
  padding-block: 1vh;
  align-items: center;
  color: var(--light-mode-white-color);
  border: 1px solid var(--light-mode-grey-border-color);
  border-bottom: none;
}
.announcement-modal-header h2,
.announcement-modal-header .close-button {
  font-size: 1.1rem;
  font-weight: 700;
  margin-block-end: 0;
  background: none;
  border: none;
  color: inherit;
}

.announcement-modal-content {
  padding-inline: 1.3vw;
  margin-top: 2vh;
  font-weight: 500;
}

.modal-tail {
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-right: 22px solid var(--light-mode-grey-border-color);
  position: absolute;
  left: 13px;
  top: 130px;
  z-index: 2;
}

.modal-tail:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 21px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 23px solid var(--light-mode-white-color);
  position: absolute;
  top: -21px;
  left: 1px;
}
.announcement-modal .form-check {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 10px;
  gap: 5px;
}
.announcement-modal .form-check input {
  background-color: var(--light-mode-white-color);
  border-color: var(--light-mode-text-color);
}
.announcement-modal .form-check-input:checked {
  background-color: var(--light-mode-button-outline-base-color);
  border-color: var(--light-mode-border-color);
}

.darkMode .announcement-modal .form-check input {
  background-color: var(--dark-mode-modal-background-color);
  border-color: var(--dark-mode-text-color);
}
.darkMode .announcement-modal {
  background-color: var(--dark-mode-modal-background-color);
  border-color: var(--dark-mode-pink-border-color);
  box-shadow: 4px 4px 8px var(--dark-mode-button-outline-base-color);
}
.darkMode .modal-tail {
  border-right: 22px solid var(--dark-mode-pink-border-color);
}
.darkMode .modal-tail::after {
  border-right: 23px solid var(--dark-mode-modal-background-color);
}
