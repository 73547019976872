.manage_role_main_div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -0.6em;
  font-family: "Graphik";
}

.title_div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  top: -0.3em;
  margin-top: 15px;
}

.title_container_div {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.users_list_div {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin-top: 1.5em;
}

.main_container_div {
  display: flex;
  justify-content: center;
  min-height: 106vh;
}

.input_eid_div {
  display: flex;
  align-items: center;
  height: 6vh;
  width: 100%;
}

.input_eid {
  margin-right: 1em;
  border: 1.5px solid var(--purple_01);
}
.input_eid:focus {
  margin-right: 1em;
  border: 1.5px solid var(--purple_01);
  box-shadow: 0 0 0.35rem var(--purple_01);
}

.title_label {
  font-weight: 700;
  font-size: 1.5em;
}

.eid_btn_div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 90%;
  margin: 0.3em;
}

.eid_btn {
  height: 3em;
  font-weight: 700;
  font-size: 1.1em;
}

.x_btn {
  width: 3em;
  height: 3em;
  font-weight: 700;
  font-size: 1.1em;
  position: relative;
  right: 0.5em;
}

.plus_icon {
  cursor: pointer;
  position: relative;
  left: 0.7em;
}

.save_changes_btn {
  width: 11em;
  height: 3em;
  font-weight: 700;
  font-size: 1.1em;
  margin-top: 1em;
  margin-bottom: 3em;
}

.spinner_position {
  position: relative;
  top: 0.5em;
}

.input_eid_add_btn {
  margin-bottom: 2px;
}

/* dark mode section */

.darkMode_title_label {
  font-weight: 700;
  font-size: 1.5em;
  color: var(--dark-mode-text-color);
}

.darkMode_tooltip {
  color: var(--dark-mode-text-color);
  background-color: var(--dark-mode-feedback-color);
}
