.main_div_container {
  padding-bottom: 13vh;
  margin-top: 15px;
  overflow-x: scroll;
}

#review-curated_content {
  display: flex;
  align-items: center;
}

.date_rcc_column {
  width: 9em;
}
#table_title {
  width: 3em;
}

#table_url {
  width: 3em;
}

.paginationContainer {
  margin-top: 1em;
}
.td_class {
  max-width: 19ch;
  min-width: 8rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  font-family: 16px "Graphik";
}
.delegates_row {
  max-width: 15ch;
  min-width: 8rem;

  display: flex;
  flex-direction: row;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.overlay_rcc {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light-mode-loading-overlay);
  z-index: 12;
  cursor: pointer;
  align-items: center;
  text-align: center;
  display: grid;
}

.th_display {
  display: flex;
}

#request-table-tabs {
  display: flex;
  align-items: flex-start;
}

.table_filter_arrow {
  border: none;
  background: none;
}

.delegates_list {
  text-justify: initial;
  color: var(--light-mode-text-color);
  width: 12rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  list-style: none;
  border: 1px solid;
  text-align: left;
  border-color: var(--dark-mode-modal-border);
  background: var(--light-mode-white-color);
  margin-right: 7px;
}

.hideList {
  display: none;
}
.delegates_list_dark {
  background-color: var(--dark-mode-table);
  color: var(--dark-mode-text-color);
}
.table_filter_arrow {
  border: none;
  background: none;
}

.table_filter_status_arrow {
  border: none;
  background: none;
  position: relative;
  top: 0.12em;
}

.table_filter_arrow {
  width: 5px;
}

.status_filter_menu {
  border: 1.5px solid var(--light-mode-border-color);
}

.best_bet_tab {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--light-mode-link-color-normal);
  font-size: 1.1em;
}
.best_bet_tab:hover {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--light-mode-link-color-hover);
  font-size: 1.1em;
}

.delegates_row {
  max-width: 15ch;
  min-width: 11rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.users_icon {
  margin-top: 6px;
  margin-left: 3px;
}

.topic_card_tab {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--light-mode-link-color-normal);
  font-size: 1.1em;
}
.topic_card_tab:hover {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--light-mode-link-color-hover);
  font-size: 1.1em;
}

.how_to_tab {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--light-mode-link-color-normal);
  font-size: 1.1em;
}

.syn_tab {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--light-mode-syn-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--dark-mode-text-color);
}

.how_to_tab:hover {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--light-mode-link-color-hover);
  font-size: 1.1em;
}

.tabs_and_btns_rcc {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.tabs_and_btns_rcc,
.request_table_tabs {
  align-content: flex-start;
  margin-bottom: 1em;
  width: 100%;
}

.active_bb_tab {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--light-mode-bb-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
}

.active_bb_tab:hover {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--light-mode-bb-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--light-mode-link-color-hover);
}

.active_tc_tab {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--light-mode-tc-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
}

.active_tc_tab:hover {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--light-mode-tc-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--light-mode-link-color-hover);
}

.active_ht_tab {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--light-mode-ht-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
}

.active_ht_tab:hover {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--light-mode-ht-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--light-mode-link-color-hover);
}

.active_syn_tab {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--light-mode-syn-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
}

.active_ht_tab:hover {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--light-mode-syn-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--light-mode-link-color-hover);
}

.table {
  width: 100%;
}

.export_requests_div {
  display: flex;
  justify-content: flex-end;
}

.synonyms_col_table {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 26em;
  min-width: 18em;
  overflow: hidden;
}

.font_weight_button {
  /* it's ok because pills can act as buttons on some parts of the app */
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.export_tc_btn {
  margin-left: 10px;
  background-color: var(--light-mode-btn-info-color);
}

.req_bb_btn_color {
  background-color: var(--light-mode-bb-icon-color);
  color: var(--light-mode-white-text);
}

.req_bb_btn_color:hover {
  background-color: var(--light-mode-bb-hover-color);
  box-shadow: 0em 0em 0.1em 0.2em var(--dark-mode-green-03-color);
  border: 1px solid var(--light-mode-hover-icons);
  color: var(--light-mode-white-text);
}

/* only for this button's "click" event */
button.req_bb_btn_color:active,
.req_bb_btn_color:first-child:active {
  background-color: var(--light-mode-bb-icon-color);
  border: 1px solid var(--light-mode-hover-icons);
  box-shadow: 0em 0em 0.1em 0.2em var(--dark-mode-green-03-color);
  color: var(--light-mode-white-text);
}

.req_tc_btn_color {
  background-color: var(--light-mode-tc-icon-color);
  color: var(--light-mode-white-text);
}

.req_tc_btn_color:hover {
  background-color: var(--light-mode-tc-hover-color);
  border: 1px solid var(--light-mode-tc-icon-color);
  box-shadow: 0em 0em 0.1em 0.2em var(--dark-mode-cyan-02-color);
  color: var(--light-mode-white-text);
}

/* only for this button's "click" event */
.req_tc_btn_color:active,
.req_tc_btn_color:first-child:active {
  background-color: var(--light-mode-tc-icon-color);
  border: 1px solid var(--light-mode-tc-icon-color);
  box-shadow: 0em 0em 0.1em 0.2em var(--dark-mode-cyan-02-color);
  color: var(--light-mode-white-text);
}

.req_ht_btn_color {
  background-color: var(--light-mode-ht-icon-color);
  color: var(--light-mode-white-text);
}

.req_ht_btn_color:active {
  background-color: var(--light-mode-syn-hover-color);
  border: 1px solid var(--dark-mode-synonyms-form-color);
  box-shadow: 0em 0em 0.1em 0.2em var(--dark-mode-synonyms-form-color);
}

.req_syn_btn_color {
  background-color: var(--light-mode-syn-icon-color);
  color: var(--light-mode-white-text);
}

.req_ht_btn_color:hover {
  background-color: var(--light-mode-ht-hover-color);
  border: 1px solid var(--light-mode-ht-icon-color);
  box-shadow: 0em 0em 0.1em 0.2em var(--light-mode-ht-icon-color);
  color: var(--light-mode-white-text);
}

/* only for this button's "click" event */
.req_ht_btn_color:active,
.req_ht_btn_color:first-child:active {
  background-color: var(--light-mode-ht-icon-color);
  border: 1px solid var(--light-mode-ht-icon-color);
  box-shadow: 0em 0em 0.1em 0.2em var(--light-mode-ht-icon-color);
  color: var(--light-mode-white-text);
}

.req_syn_btn_color:hover {
  background-color: var(--light-mode-syn-hover-color);
  border: 1px solid var(--dark-mode-synonyms-form-color);
  box-shadow: 0em 0em 0.1em 0.2em var(--dark-mode-synonyms-form-color);
  color: var(--light-mode-white-text);
}
button.req_syn_btn_color:active,
.req_syn_btn_color:first-child:active {
  background-color: var(--light-mode-syn-icon-color);
  border: 1px solid var(--light-mode-syn-icon-color);
  box-shadow: 0em 0em 0.1em 0.2em var(--dark-mode-synonyms-form-color);
  color: var(--light-mode-white-text);
}

.export_tc_btn {
  height: 30px;
}

.div_separator {
  display: flex;
  flex-direction: row;
  margin: 0.5em;
  margin-right: 1px;
  white-space: nowrap;
}
.div_separator_2 {
  display: flex;
  flex-direction: row;
  margin-right: 2em;
}

.total_amount_span {
  position: relative;
  top: 0.4em;
  color: var(--light-mode-text-color);
}

.loading_spinner_margin {
  margin: 1em;
}

.page_btn_light {
  color: var(--light-mode-link-color-normal);
  background-color: transparent;
  border: 1px solid #00000021;
  width: 2.3em;
  height: 2.3em;
}

.page_btn_light:hover {
  color: var(--light-mode-link-color-normal);
  background-color: var(--light-mode-light-grey-color-3);
  border: 1px solid #00000021;
  width: 2.3em;
  height: 2.3em;
}

.arrow_btn_light,
.arrow_btn_light:hover {
  color: var(--light-mode-light-grey-color-2);
  border: 1px solid #00000021;
  width: 2.3em;
  height: 2.3em;
  cursor: default;
}

.page_item_li_active > button {
  background-color: var(--light-mode-feedback-purple);
  color: var(--dark-mode-text-color);
}

.redirect_to_request_btn {
  border: none;
  background: transparent;
  color: var(--light-mode-link-color-normal);
}

.redirect_to_request_btn:hover {
  background: transparent;
  color: var(--purple_01);
  text-decoration: underline;
}

.table_div_rcc {
  overflow-x: auto;
  left: 0.5em;
  margin-right: 1em;
  margin-left: 1em;
}
/* DARK MODE */

.active_bb_tab_dark {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--dark-mode-bb-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--dark-mode-text-color);
}
.active_bb_tab_dark:hover {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--dark-mode-bb-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--dark-mode-link-color-hover);
}

.active_tc_tab_dark {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--dark-mode-tc-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--dark-mode-text-color);
}

.active_tc_tab_dark:hover {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--dark-mode-tc-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--dark-mode-link-color-hover);
}

.active_ht_tab_dark {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--dark-mode-ht-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--dark-mode-text-color);
}
.active_syn_tab_dark {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--dark-mode-syn-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--dark-mode-text-color);
}

.active_syn_tab_dark:hover {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--dark-mode-synonyms-form-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--dark-mode-link-color-hover);
}

.active_ht_tab_dark:hover {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid var(--dark-mode-ht-icon-color);
  width: 7em;
  height: 3em;
  font-size: 1.1em;
  color: var(--dark-mode-link-color-hover);
}

.tbody_dark {
  color: var(--dark-mode-text-color);
}

.total_results_color_dark {
  color: var(--dark-mode-text-color);
}

.table_filter_button > button {
  color: var(--dark-mode-text-color);
}

.page_btn_dark {
  color: var(--dark-mode-link-color-normal);
  background-color: var(--dark-mode-page-nav-color);
  border: 1px solid var(--dark-mode-page-nav-color);
  width: 2.3em;
  height: 2.3em;
}

.page_btn_dark:hover {
  color: var(--dark-mode-text-color);
  background-color: var(--dark-mode-btn-info-color);
  border: 1px solid var(--dark-mode-btn-info-color);
  width: 2.3em;
  height: 2.3em;
}

.page_dot_dot_btn_dark {
  color: var(--dark-mode-text-color);
  background-color: var(--dark-mode-page-nav-color);
  border: 1px solid var(--dark-mode-page-nav-color);
  width: 2.3em;
  height: 2.3em;
}

.arrow_btn_dark,
.arrow_btn_dark:hover {
  color: var(--dark-mode-text-color);
  background-color: var(--dark-mode-page-nav-color);
  border: 1px solid var(--dark-mode-page-nav-color);
  width: 2.3em;
  height: 2.3em;
  cursor: default;
}

.page_item_li_dark_active > button {
  color: var(--dark-mode-text-color);
  background-color: var(--dark-mode-link-color-visited);
}

.status_filter_menu_dark {
  border: 1.5px solid var(--light-mode-border-color);
  background-color: var(--dark-mode-page-nav-color);
}

.status_filter_menu_dark > li > a {
  color: var(--dark-mode-text-color);
}

.redirect_to_request_btn_dark {
  border: none;
  background: transparent;
  color: var(--dark-mode-link-color-normal);
}

.redirect_to_request_btn_dark:hover {
  background: transparent;
  color: var(--dark-mode-link-color-hover);
  text-decoration: underline;
}

.best_bet_tab_dark {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--dark-mode-link-color-normal);
  font-size: 1.1em;
}

.best_bet_tab_dark:hover {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--dark-mode-link-color-hover);
  font-size: 1.1em;
}

.topic_card_tab_dark {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--dark-mode-link-color-normal);
  font-size: 1.1em;
}

.topic_card_tab_dark:hover {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--dark-mode-link-color-hover);
  font-size: 1.1em;
}

.how_to_tab_dark {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--dark-mode-link-color-normal);
  font-size: 1.1em;
}

.syn_tab_dark {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--dark-mode-link-color-normal);
  font-size: 1.1em;
}

.how_to_tab_dark:hover {
  border: none;
  background-color: transparent;
  width: 7em;
  height: 3em;
  color: var(--dark-mode-link-color-hover);
  font-size: 1.1em;
}

.req_bb_btn_color_dark {
  background-color: var(--dark-mode-bb-icon-color);
}

.req_bb_btn_color_dark:hover {
  background-color: var(--dark-mode-bb-hover-color);
  border: 1px solid var(--dark-mode-bb-icon-color);
  box-shadow: 0em 0em 0.1em 0.2em var(--dark-mode-bb-icon-color);
}

.req_bb_btn_color_dark:active,
.req_bb_btn_color_dark:first-child:active {
  background-color: var(--dark-mode-bb-icon-color);
}

.req_tc_btn_color_dark {
  background-color: var(--dark-mode-tc-icon-color);
}

.req_tc_btn_color_dark:hover {
  background-color: var(--dark-mode-tc-hover-color);
  border: 1px solid var(--dark-mode-tc-icon-color);
  box-shadow: 0em 0em 0.1em 0.2em var(--dark-mode-tc-icon-color);
}

.req_tc_btn_color_dark:active,
.req_tc_btn_color_dark:first-child:active {
  background-color: var(--dark-mode-tc-icon-color);
}

.req_ht_btn_color_dark {
  background-color: var(--dark-mode-ht-icon-color);
}

.req_ht_btn_color_dark:hover {
  background-color: var(--dark-mode-ht-hover-color);
  border: 1px solid var(--dark-mode-ht-icon-color);
  box-shadow: 0em 0em 0.1em 0.2em var(--dark-mode-ht-icon-color);
}

.req_ht_btn_color_dark:active,
.req_ht_btn_color_dark:first-child:active {
  background-color: var(--dark-mode-ht-icon-color);
}

.req_syn_btn_color_dark {
  background-color: var(--dark-mode-syn-icon-color);
  color: var(--dark-mode-dark-text);
}

.req_syn_btn_color_dark:hover {
  filter: brightness(150%);
  background-color: var(--dark-mode-syn-hover-color);
  border: 1px solid var(--dark-mode-syn-icon-color);
  box-shadow: 0em 0em 0.1em 0.2em var(--dark-mode-syn-icon-color);
  font-weight: bold;
}

.req_syn_btn_color_dark:active,
.req_syn_btn_color_dark:first-child:active {
  background-color: var(--dark-mode-syn-icon-color);
}
