.label-text {
  size: 16px;
  font-family: Graphik;
  font-weight: 600;
  color: var(--light-mode-text-color);
  margin-bottom: 2px;
  text-align: -webkit-auto;
  margin-left: 3px;
  white-space: nowrap;
}
.invalid {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--light-mode-red-01-color);
  white-space: pre-line;
  text-align-last: center;
  margin-top: 1px;
  padding-bottom: 7px;
}
.darkMode .form-control.is-invalid {
  border-color: var(--dark-mode-red-01-color);
}
.darkMode .label-text {
  color: var(--dark-mode-text-color);
}
.required:after {
  content: " *";
  color: var(--light-mode-red-01-color);
}
.topic-card-form {
  height: 100%;
  padding-bottom: 120px;
  margin-block: 1.7rem;
  font-family: "Graphik";
}
.title-url {
  display: flex;
  justify-content: center;
}
.description {
  margin: 0px auto;
  width: 55%;
}
.title {
  margin-right: 30px;
  flex: 0 0 auto;
  width: 45.666667%;
}
.target {
  margin-right: 30px;
  flex: 0 0 auto;
  width: 48.666667%;
  white-space: nowrap;
}
.url {
  flex: 0 0 auto;
  width: 45.666667%;
}
.info-icon {
  width: 1.3em;
  height: 1.3em;
  color: var(--bs-light-mode-purple);
}
.span-title {
  color: var(--light-mode-text-color);
  font-weight: bold;
  font-style: normal;
  display: flex;
}
.autocomplete-results[data-v-b293b8bc] {
  padding: 0;
  margin: 0;
  border: 1px solid var(--light-mode-autocomplete-results-border-color);
  max-height: 155px;
  overflow: auto;
  width: 87%;
  text-align: left;
  position: absolute;
  top: 40px;
  left: 15px;
  z-index: 1;
  overflow-x: hidden;
}
.autocomplete-result {
  padding: 3px 5px 3px 12px;
  background-color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tc-pills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}
.darkMode .autocomplete-result[data-v-b293b8bc] {
  background-color: var(--dark-mode-landing-bg-color);
  color: var(--dark-mode-text-color);
}
.darkMode ul.autocomplete-results > li {
  background: var(--dark-mode-landing-bg-color);
  color: var(--dark-mode-text-color);
}
.darkMode .autocomplete-results {
  border: 1px solid var(--dark-mode-border-color);
}
.darkMode .span-title {
  color: var(--dark-mode-text-color);
}
.tooltip-dark {
  background-color: var(--dark-mode-modal-border);
  border-radius: 5%;
  padding: 4px;
}
textarea {
  resize: none;
}

.diamond {
  color: var(--light-mode-feedback-color);
  font-weight: normal;
  font-size: 0.8em;
}
.darkMode .diamond {
  color: var(--dark-mode-feedback-color);
}

.line-item {
  display: flex;
  justify-content: flex-start;
  margin-top: 2px;
  margin-bottom: 8px;
}
.line-item-title {
  margin-right: 30px;
  flex: 0 0 auto;
  width: 48.666667%;
}
.line-item-url {
  flex: 0 0 auto;
  width: 48.666667%;
}
.line-item-input {
  display: flex;
  justify-content: flex-start;
}
.steps_icons {
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plus_icon_kw {
  width: 24px;
  height: 22px;
  margin-top: 2px;
  margin-left: 18px;
}

.plus_icon_delegate {
  width: 24px;
  height: 22px;
  margin-top: 2px;
  margin-left: 4px;
  fill: var(--purple_01);
  position: relative;
  top: 0.5em;
  left: 0.43em;
}

.plus_icon_delegate:hover {
  fill: var(--light-mode-green-01-color);
}

.plus-arrow-trash {
  margin: 3px;
  width: 24px;
  height: 22px;
  cursor: pointer;
  margin-bottom: 20px;
  color: var(--light-mode-icon-color);
  opacity: 1;
}
.plus-arrow-trash:hover {
  color: var(--light-mode-border-header-bb);
}
.kw-plus-icon:focus .plus-arrow-trash:focus {
  background-color: var(--light-mode-white-color);
  box-shadow: 0 0 0 0.2rem var(--light-mode-box-shadow-color);
}
.pills {
  background-color: var(--light-mode-pills);
  border-style: none;
  color: black;
  font-weight: bold;
  font-family: "Graphik";
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}
.pills-red {
  color: var(--light-mode-white-text);
  border-style: none;
  font-weight: bold;
  font-family: "Graphik";
}
.pills-group {
  display: inline-flex;
  flex-flow: wrap;
}
.pills:hover {
  background-color: var(--light-mode-pills-hover);
}
.pill {
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.keywords {
  margin-right: 30px;
  flex: 0 0 auto;
  width: 107.666667%;
}
.kw-plus-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  margin-top: -32px;
  margin-left: 25%;
  padding-left: 1%;
  color: var(--light-mode-icon-color);
}
.kw-plus-icon:hover,
.kw-plus-icon:focus-visible {
  color: var(--light-mode-border-header-bb);
}

.tcurl-and-rocket-styles {
  display: -webkit-box;
}

#tc-form-url-input {
  height: fit-content;
}
.invalid-field-div-tcurl {
  display: flex;
  flex-wrap: wrap;
  width: 340px;
  color: var(--light-mode-invalid-feedback);
  font-size: 80%;
  text-align: center;
}
.ai-loading-tc-url {
  position: relative;
  margin-left: 13px;
}
.tc-genai-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80%;
  color: var(--light-mode-invalid-feedback);
  text-align: center;
}
.invalid-field-div-tcurl-hidden {
  display: none;
}
/* Descripion and Comments Scrollbar*/
#description::-webkit-scrollbar,
#comments::-webkit-scrollbar {
  width: 8px;
}
.darkMode #description::-webkit-scrollbar-track,
.darkMode #comments::-webkit-scrollbar-track {
  background-color: var(--dark-mode-link-button-bg-color);
}
#description::-webkit-scrollbar-thumb,
#comments::-webkit-scrollbar-thumb {
  background-color: #c6c6c6;
  border-radius: 5px;
}
.darkMode textarea::-webkit-scrollbar {
  width: 8px;
  background-color: var(--dark-mode-link-button-bg-color);
}
.darkMode textarea::-webkit-scrollbar-track {
  background-color: transparent;
}
.darkMode textarea::-webkit-scrollbar-thumb {
  background-color: var(--dark-mode-text-color);
  border-radius: 5px;
}
textarea::-webkit-scrollbar {
  width: 5px;
}
textarea::-webkit-scrollbar-track {
  background-color: var(--light-mode-landing-bg-color);
}
textarea::-webkit-scrollbar-thumb {
  background-color: #96968c;
  border-radius: 5px;
}

/* Checkbox */

.check-primary {
  margin-left: 6px;
}
.topic-card-form input[type="checkbox"] {
  margin-right: -25px;
  cursor: pointer;
  font-size: 17px;
  opacity: 0;
}
.topic-card-form input[type="checkbox"]:focus + label:before {
  color: var(--light-mode-form-control-focus);
  background-color: transparent;
  box-shadow: 0 0 0 0.2rem var(--light-mode-box-shadow-color);
}
:focus {
  outline-color: var(--light-mode-secondary-button-hover-color);
}
.topic-card-form input[type="checkbox"] + label:before {
  border: 1px solid var(--light-mode-border-color);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 0.25em;
}

input[type="checkbox"]:checked + label:before {
  background: var(--light-mode-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  text-align: center;
  border-radius: 0.25em;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
}

input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}
/* Buttons */
.save-changes {
  margin-right: 30px;
}

.tc-form-buttons-div {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: -webkit-fill-available;
  justify-content: space-evenly;
  align-self: center;
  align-items: center;
  gap: 15px;
}

.primary-btn {
  width: 200px;
  height: 50px;
  border-radius: 0.6em;
  font-family: "Graphik";
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
}
.primary-btn-colour,
.primary-btn-colour:disabled {
  background-color: var(--light-mode-btn-info-color);
  border-style: none;
  color: var(--light-mode-white-text);
}
.primary-btn-colour:hover {
  background-color: var(--light-mode-secondary-button-hover-color);
  color: var(--light-mode-dropdown-item-text);
}
button#implement-button:active {
  color: var(--light-mode-dropdown-item-text);
  background-color: var(--light-mode-active-btn-primary-forms);
  border-color: var(--light-mode-active-btn-primary-forms);
}
.primary-btn-colour::after {
  background-color: var(--light-mode-btn-info-color);
}
.secondary-btn-colour,
.secondary-btn-colour:disabled {
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-light-grey-color);
}

#tc-implement-btn {
  border-color: var(--light-mode-secondary-button-color);
  background-color: var(--light-mode-secondary-button-color);
}

#tc-implement-btn:disabled {
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-light-grey-color);
}

#tc-implement-btn:hover {
  background-color: var(--light-mode-button-outline-base-color);
}

.darkMode #tc-implement-btn:hover {
  background-color: var(--dark-mode-btn-primary-color-hover);
}

.darkMode #tc-remove-request-button:hover {
  border: 2px solid var(--dark-mode-red-color-warning);
  background: var(--dark-mode-red-color-warning);
  color: var(--dark-mode-dark-text);
}

.darkMode #tc-reject-button:hover {
  border: 2px solid var(--dark-mode-red-color-warning);
  background: var(--dark-mode-red-color-warning);
  color: var(--dark-mode-dark-text);
}

.darkMode #tc-cancel-request-button:hover {
  order: 2px solid var(--dark-mode-red-color-warning);
  background: var(--dark-mode-red-color-warning);
  color: var(--dark-mode-dark-text);
}

#tc-save-changes-btn:hover {
  background-color: var(--light-mode-button-outline-base-color);
  color: var(--light-mode-white-color);
}

.darkMode #tc-save-changes-btn:hover {
  background-color: var(--dark-mode-btn-primary-color-hover);
}

#tc-save-changes-btn {
  background-color: transparent;
  border-color: var(--light-mode-secondary-button-color);
  color: var(--light-mode-secondary-button-color);
}

#tc-save-changes-btn:disabled {
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-light-grey-color);
}

#tc-preview-button:disabled,
#tc-save-draft-btn:disabled {
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-light-grey-color);
}
.secondary-btn-colour:hover {
  background-color: var(--light-mode-secondary-button-hover-color);
  border-style: none;
  color: var(--light-mode-white-text);
}
button#tc-preview-button:active,
button#tc-save-draft-btn:active {
  background-color: transparent;
  color: var(--light-mode-secondary-button-color);
  border: 2px solid var(--light-mode-secondary-button-text-color);
}
.darkMode .secondary-btn-colour {
  color: var(--dark-mode-text-color);
  border-color: var(--dark-mode-text-color);
}

.darkMode .primary-btn-colour:hover {
  background-color: var(--dark-mode-btn-primary-color-hover);
  border: 2px solid var(--light-mode-button-outline-base-color);
}

#tc-request-button {
  background-color: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  font-size: 17px;
}

#tc-request-button:disabled {
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-light-grey-color);
}

#tc-request-button:hover {
  background-color: var(--dark-mode-primary-button-hover-color);
}
.darkMode #tc-request-button {
  background: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  color: var(--dark-mode-text-color);
}
.darkMode #tc-request-button:hover {
  background-color: var(--dark-mode-btn-primary-color-hover);
}

#tc-save-changes-btn {
  border-color: var(--light-mode-secondary-button-color);
  color: var(--light-mode-secondary-button-color);
}

.darkMode #tc-save-changes-btn {
  color: var(--dark-mode-text-color);
  border-color: var(--dark-mode-text-color);
}
.darkMode #tc-save-changes-btn:hover {
  background: var(--light-mode-secondary-button-color);
  border-color: var(--light-mode-secondary-button-color);
  color: var(--dark-mode-text-color);
}

.people {
  margin-top: 20px;
}
.comments {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn-adjust-content-cancel {
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap;
}
.btn-adjust-content-confirm-remove {
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap;
  font-weight: bold;
}

.tc-btn-form-styles {
  width: 200px;
  height: 50px;
  border-radius: 0.6em;
  font-family: "Graphik";
  font-size: 18px;
  font-weight: 700;
  border: 2px solid;
}

.tc_compare_sticky_div {
  top: 1em;
  z-index: 15;
}

.tc-comparison-table-backdrop {
  background-color: rgb(0 0 0 / 50%);
  z-index: -1;
  width: 112vw;
  height: 60em;
  position: absolute;
  top: -6em;
  left: -7em;
}

.tc-comparison-tables-div {
  background: var(--light-mode-white-color);
  position: sticky;
  top: 0em;
  padding: 1em;
  border-radius: 0.3em;
}

.tc-comparison-tables-title {
  background-color: var(--light-mode-white-color);
  color: var(--light-mode-link-color-normal);
  font-weight: 600;
}

.tc-table-fields-col {
  font-weight: 600;
  color: var(--light-mode-text-color);
  text-decoration: underline;
  font-size: 1.1em;
}
.tc-table-before-col {
  font-weight: 600;
  color: var(--light-mode-red-color-warning);
  text-decoration: underline;
  font-size: 1.1em;
}
.tc-table-after-col {
  font-weight: 600;
  color: var(--light-mode-title-color-blue);
  text-decoration: underline;
  font-size: 1.1em;
}
.darkMode .tc-table-after-col {
  color: var(--dark-mode-title-color-blue);
}
.tc-close-table-btn-div {
  display: flex;
  justify-content: flex-end;
}

.tc-close-compare-table-btn {
  position: relative;
  z-index: 13;
}

#tc-compare-table-button {
  border-color: var(--light-mode-secondary-button-color);
  color: var(--light-mode-secondary-button-color);
}

#tc-compare-table-button:hover {
  background-color: var(--light-mode-button-outline-base-color);
  color: var(--light-mode-white-color);
}
#tc-compare-table-button:disabled {
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-light-grey-color);
}
.darkMode #tc-compare-table-button {
  color: var(--dark-mode-gray-text-color);
  border: 2px solid;
  border-color: 2px var(--dark-mode-gray-text-color);
}

.darkMode #tc-compare-table-button:hover {
  background-color: var(--light-mode-btn-info-color);
  border-color: var(--light-mode-btn-info-color);
}

.topic-card-form-buttons-div {
  position: relative;
  top: -8em;
}

.table-cell-text-wrap {
  overflow-wrap: anywhere;
}

#tc-comparison-table-main-div {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#tc-comparison-table-main-div::-webkit-scrollbar {
  display: none;
}

.tc-request-info-title-div {
  color: var(--light-mode-white-text);
  background-color: var(--light-mode-tc-icon-color);
  border-radius: 0.2em;
  border: 1px solid var(--light-mode-tc-icon-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
}

.tc-request-info-parent-div {
  padding-left: 11em;
  padding-right: 11em;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
  background-color: var(--light-mode-light-grey-color-3);
  position: relative;
  top: -0.3em;
  left: -5.4px;
}
#tc-preview-button {
  background: transparent;
  border: 2px solid var(--light-mode-secondary-button-color);
  color: var(--light-mode-secondary-button-text-color);
}
#tc-preview-button:hover {
  background-color: var(--light-mode-button-hover-color);
  color: var(--light-mode-white-color);
}

.darkMode #tc-request-button:disabled,
.darkMode #tc-preview-button:disabled,
.darkMode #tc-save-draft-btn:disabled {
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-light-grey-color);
}
.darkMode #tc-preview-button:hover {
  background: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  color: var(--dark-mode-text-color);
}
.darkMode #tc-preview-button {
  border: 2px solid var(--dark-mode-secondary-button-border-color);
  color: var(--dark-mode-base-btn-text-color);
}

#tc-save-draft-btn {
  background: transparent;
  border: 2px solid var(--light-mode-secondary-button-color);
  color: var(--light-mode-secondary-button-text-color);
}
#tc-save-draft-btn:hover {
  background-color: var(--light-mode-button-hover-color);
  color: var(--light-mode-white-color);
}

.darkMode #tc-save-draft-btn:hover {
  background: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  color: var(--dark-mode-text-color);
}
.darkMode #tc-save-draft-btn {
  border: 2px solid var(--dark-mode-secondary-button-border-color);
  color: var(--dark-mode-base-btn-text-color);
}

.darkMode .form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
  background-color: var(--dark-mode-secondary-button-color);
  position: relative;
  top: -0.3em;
  left: -5.4px;
}

.darkMode .form-select:disabled {
  background-color: var(--dark-mode-link-button-bg-color);
  color: var(--dark-mode-text-color);
}

.darkMode .tc-request-info-title-div {
  color: var(--dark-mode-disclaimers-font-bb);
  background-color: var(--dark-mode-tc-icon-color);
}

.darkMode .tc-comparison-tables-div {
  background-color: var(--dark-mode-modal-background-color);
}

.darkMode .tc-comparison-tables-title {
  background-color: var(--dark-mode-modal-background-color);
  color: var(--dark-mode-link-color-normal);
}

.darkMode .tc-table-fields-col {
  color: var(--dark-mode-text-color);
}
#tc-request-button {
  font-size: 17px;
}
.darkMode #tc-request-button {
  background: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  color: var(--dark-mode-text-color);
}
.darkMode #tc-request-button:hover {
  background-color: var(--dark-mode-btn-primary-color-hover);
}

.darkMode #tc-save-changes-btn {
  border-color: var(--dark-mode-gray-text-color);
  color: var(--dark-mode-text-color);
}

.darkMode #tc-save-changes-btn:hover {
  background: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  color: var(--dark-mode-text-color);
}

.draft-save-changes-btn-style {
  background: transparent;
  border: 2px solid var(--light-mode-secondary-button-color);
  color: var(--light-mode-secondary-button-text-color);
}
.draft-save-changes-btn-style:hover {
  background-color: var(--light-mode-button-hover-color);
  color: var(--light-mode-white-color);
}

.draft-save-changes-btn-style:disabled {
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-light-grey-color);
}

.darkMode .draft-save-changes-btn-style:hover {
  background: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  color: var(--dark-mode-text-color);
}

.darkMode .draft-save-changes-btn-style {
  background: transparent;
  border: 2px solid var(--dark-mode-secondary-button-border-color);
  color: var(--dark-mode-base-btn-text-color);
}

.owner-css-save-changes-btn {
  background: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  color: #ffffff;
}
.owner-css-save-changes-btn:disabled {
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-light-grey-color);
}

.owner-css-save-changes-btn:hover {
  background: var(--light-mode-button-outline-base-color);
  color: white;
  border: 2px solid var(--light-mode-button-outline-base-color);
}

.darkMode .owner-css-save-changes-btn:hover {
  background-color: var(--dark-mode-btn-primary-color-hover);
}
.darkMode button#tc-save-draft-btn {
  background: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  color: var(--dark-mode-text-color);
}

.tc-delegates-field-gap {
  margin-top: -20px;
}

.tc-delegates-input-div {
  display: flex;
  min-width: 1em;
  width: 107%;
}
