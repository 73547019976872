@keyframes twinkle {
  0% {
    transform: scale(0);
    opacity: 0;
    visibility: visible;
  }
  50% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    transform: scale(0);
    opacity: 0;
    visibility: hidden;
  }
}

.twinkle {
  animation: twinkle 1.5s infinite;
}
.light-gradient {
  background: linear-gradient(90deg, #172393 0%, #a100ff 54%, #7200bb 100%);
}
.dark-gradient {
  background: linear-gradient(90deg, #9099ed 0%, #bf7fe5 54%, #dbb7f2 100%);
}

.modal-button-second.pink {
  background-color: var(--Core-Purple-2, #dcafff);
  color: var(--light-mode-text-color);
}
