@font-face {
  font-family: "Graphik";
  src: url("./assets/fonts/Graphik-Regular-Web.eot");
}

html,
body {
  max-width: 100%;
  overflow: hidden;
}

:root {
  --sidemenu-width: 16em;
  --contracted-sidemenu-width: 4em;
  --bestbet-field-width: calc(
    250px + (750 - 250) * ((100vw - 500px) / (1920 - 500))
  ); /* [min size]px + ([max size] — [min size]) * ((100vw — [min vw width]px) / ([max vw width] — [min vw width]))  */
  --feedback-small-vh-top: calc(
    (300vw + 1920px) / (625 - 600)
  ); /*Using 'vw' as it depends on the width of the screen. Position of the box when having a small viewport height*/
  --purple_01: #a100ff;
  --red-pills-hover: #ff324680;
  --unselected-feedback-rate: #707070;
  --modal-purple-header: #4f1684;
  /* DARK MODE VARIABLES */

  --dark-mode-bg-disabled-pagination-btns: #201d22cc;
  --dark-mode-synonyms-form-color: #ff6dc9;
  --dark-mode-synonyms-hover-button-color: #f7add2;
  --dark-mode-background-color: rgb(3, 0, 15);
  --dark-mode-btn-primary: #a055f5;
  --dark-mode-links-inactive-promote: #a055f5;
  --dark-mode-spinner-color: #a055f5;
  --dark-mode-border-color: #7500c0;
  --dark-mode-icon-color: #7500c0;
  --dark-mode-landing-bg-color: #03000f;
  --dark-mode-footer-bg-color: #1d1823;
  --dark-mode-header-bg-color: #1d1823;
  --dark-mode-text-color: #e6e6dc;
  --dark-mode-text-color-active-tab: #f4f4f4;
  --dark-mode-link-color-normal: #008eff;
  --dark-mode-link-color-visited: #a055f5;
  --dark-mode-link-color-hover: #a055f5;
  --dark-mode-box-shadow-color: #a055f5;
  --dark-mode-link-button-bg-color: #1d1823;
  --dark-mode-home-btnbox-bg-color-hover: #460073;
  --dark-mode-btn-primary-color-hover: #460073;
  --dark-mode-serach-bar-color: #7500c0;
  --dark-mode-home-font-color: #2c3e50;
  --dark-mode-red-color-warning: #ff8490;
  --dark-mode-dark-text: #000;
  --dark-mode-base-btn-text-color: #e6e6dc;
  --dark-mode-base-btn-text-color-hover: #e6e6dc;
  --dark-mode-title-color-purple: #a055f5;
  --dark-mode-title-color-blue: #008eff;
  --dark-mode-title-color-hover: #a055f5;
  --dark-mode-invalid-feedback: #ff0000;
  --dark-mode-feedback-color: #e6e6dc;
  --dark-mode-gray-text-color: #e6e6dc;
  --dark-mode-button-outline-color: #03000f;
  --dark-mode-button-outline-bg-color: #a055f5;
  --dark-mode-button-outline-border-color: #a055f5;
  --dark-mode-button-outline-base-color: #a100ff;
  --dark-mode-orange-01-color: #ffbb80;
  --dark-mode-green-03-color: #92ff84;
  --dark-mode-cyan-02-color: #80ffff;
  --dark-mode-blue-02-color: #99b3f9;
  --dark-mode-red-01-color: #ff3246;
  --dark-mode-tab-title-color: #e6e6dc;
  --dark-mode-table-text-color: #e6e6dc;
  --dark-mode-page-item-background-active-color: #a055f5;
  --dark-mode-page-item-active-color: #03000f;
  --dark-mode-autocomplete-results-bg-color: #36313b;
  --dark-mode-btn-info-color: #a055f5;
  --dark-mode-btn-info-shadow: #a100ff;
  --dark-mode-btn-secondary-disabled-border-color: #dadada;
  --dark-mode-btn-secondary-disabled-color: #3e3b3f;
  --dark-mode-secondary-button-bg-color: #e6e6dc;
  --dark-mode-secondary-button-border-color: #e6e6dc;
  --dark-mode-secondary-button-focus-color: #e6e6dc;
  --dark-mode-secondary-button-box-shadow-color: #e6e6dc80;
  --dark-mode-secondary-button-color: #a100ff;
  --dark-mode-secondary-button-text-color: #a100ff;
  --dark-mode-primary-button-hover-color: #a100ff;
  --dark-mode-green-blue-alert-color: #00ffff59;
  --dark-mode-light-green-alert-color: #198754;
  --dark-mode-green-pill-hover: #218838;
  --dark-mode-form-disabled-color: #36313b;
  --dark-mode-page-nav-color: #36313b;
  --dark-mode-table: #36313b;
  --dark-mode-overlay-color: #36313b;
  --dark-mode-synonyms-box: #36313b;
  --dark-mode-modal-border: #dee2e6;
  --dark-mode-modal-background-color: #36313b;
  --dark-mode-disclaimers-bb: #ffc107;
  --dark-mode-disclaimers-font-bb: #03000f;
  --dark-mode-fieldTitle-compare-modal: #dee2e6;
  --dark-mode-beforeTitle-compare-modal: #ff6f7d;
  --dark-mode-afterTitle-compare-modal: #1e90ff;
  --dark-mode-icon-font-color: #000;
  --dark-mode-feedback-textbox-color: #a6a6a6;
  --dark-mode-feedback-text-color: white;
  --dark-mode-green-feedback-rate: #64ff50;
  --dark-mode-red-feedback-rate: #ff0000;
  --dark-mode-ctc-icon-color: #239eb6;
  --dark-mode-tc-icon-color: #0a7bf1;
  --dark-mode-tc-hover-color: #55a2f2;
  --dark-mode-bb-icon-color: #08af18;
  --dark-mode-bb-hover-color: #55bb48;
  --dark-mode-ht-icon-color: #ff5811;
  --dark-mode-ht-hover-color: #f4844c;
  --dark-mode-syn-icon-color: #ff30ad;
  --dark-mode-syn-hover-color: #ff70c6;
  --dark-mode-preview-bb-url-color: #92d992;
  --dark-mode-pink-border-color: #c194db;
  --dark-mode-desc-modal-pink-color: #dcafff;

  /* LIGTH MODE VARIABLES */
  --light-mode-bg-disabled-pagination-btns: #f0f0f0;
  --light-mode-synonyms-form-color: #dd0888;
  --light-mode-bb-manage-icon: #64ff5059;
  --light-mode-spinner-color: #a100ff;
  --light-mode-border-color: #a100ff;
  --light-mode-icon-color: #a100ff;
  --light-mode-promote-nav-links: #7500c0;
  --light-mode-footer-bg-color: #e6dcff;
  --light-mode-link-button-bg-color: #e6dcff;
  --light-mode-loading-overlay: rgba(234, 229, 247, 0.801);
  --light-mode-text-color: #000;
  --light-mode-dropdown-item-text: #fff;
  --light-mode-dropdown-bg-color: blueviolet;
  --light-mode-dropdown-item-bg-color: #a100ff;
  --light-mode-result-url-color: #36313b;
  --light-mode-link-color-normal: #0041f0;
  --light-mode-link-color-visited: #7500c0;
  --light-mode-link-color-hover: #a100ff;
  --light-mode-invalid-feedback: #a70000;
  --light-mode-feedback-color: #545454;
  --light-mode-header-box-shadow: #a100ff;
  --light-mode-home-btnbox-bg-color: #e6dcff;
  --light-mode-home-btnbox-bg-color-hover: #dcafff;
  --light-mode-home-font-color: #2c3e50;
  --light-mode-red-color-warning: #ff3246;
  --light-mode-active-btn-primary-forms: #460073;
  --light-mode-white-text: #fff;
  --light-mode-base-btn-text-color: #36313b;
  --light-mode-base-btn-text-color-hover: #36313b;
  --light-mode-title-color-blue: #0041f0;
  --light-mode-title-color-purple: #a100ff;
  --light-mode-title-color-hover: #a100ff;
  --light-mode-title-color-visited: #7500c0;
  --light-mode-gray-text-color: #36313b;
  --light-mode-feedback-purple: #a100ff;
  --light-mode-button-outline-base-color: #a100ff;
  --light-mode-button-outline-color: #fff;
  --light-mode-button-outline-bg-color: #a055f5;
  --light-mode-button-outline-border-color: #a055f5;
  --light-mode-green-03-color: #c1ffb9;
  --light-mode-cyan-02-color: #99ffff;
  --light-mode-red-01-color: #ff3246;
  --light-mode-green-01-color: #64ff50;
  --light-mode-white-color: #fff;
  --light-mode-tab-title-color: black;
  --light-mode-table-text-color: #31363b;
  --light-mode-total-records-text-color: #36313b;
  --light-mode-page-item-background-active-color: #a100ff;
  --light-mode-page-item-active-color: #fff;
  --light-mode-autocomplete-results-border-color: #e6e6dc;
  --light-mode-autocomplete-results-bg-color: #a100ff;
  --light-mode-box-shadow-color: #a100ff40;
  --light-mode-form-control-focus: #36133b;
  --light-mode-btn-info-color: #7500c0;
  --light-mode-btn-info-shadow: #a100ff80;
  --light-mode-searchicon-hover: #8900d9;
  --light-mode-secondary-button-bg-color: #545454;
  --light-mode-secondary-button-hover-color: #a100ff;
  --light-mode-secondary-button-focus-color: #36313b;
  --light-mode-secondary-button-box-shadow-color: #36313b80;
  --light-mode-light-grey-color: #e6e6dc;
  --light-mode-light-grey-color-2: #120f10;
  --light-mode-light-grey-color-3: lightgrey;
  --light-mode-grey-border-color: #74686c;
  --light-mode-secondary-button-color: #7500c0;
  --light-mode-secondary-button-text-color: #7500c0;
  --light-mode-button-hover-color: #a100ff;
  --light-mode-green-blue-alert-color: #00ffff59;
  --light-mode-light-green-alert-color: #64ff5059;
  --light-mode-form-disabled-color: #e9ecef;
  --light-mode-orange-color: #ff7800;
  --light-mode-btn-ht-bg-border-color-hover: #f8b376;
  --light-comment-tracker-date: #6c757d;
  --light-mode-header-bestbet: rgba(100, 255, 80, 0.25);
  --light-mode-pills-hover: #c1ffb9;
  --light-mode-pills: #64ff50;
  --light-mode-border-header-bb: #64ff50;
  --light-mode-hover-icons: #64ff50;
  --light-mode-tc-header-color: #00ffff40;
  --light-mode-banner-color: rgba(255, 235, 50, 0.8);
  --light-mode-ctc-header-color: #cce5ff;
  --light-mode-ctc-header-border-color: #b8daff;
  --light-mode-feedback-textbox-color: #575757;
  --light-mode-green-feedback-rate: #53dd22;
  --light-mode-red-feedback-rate: #db1212;
  --light-mode-ctc-icon-color: #1f7a8c;
  --light-mode-tc-icon-color: #009fe3;
  --light-mode-tc-hover-color: #006e9d;
  --light-mode-bb-icon-color: #048a11;
  --light-mode-bb-hover-color: #035f0c;
  --light-mode-ht-icon-color: #ff5500;
  --light-mode-ht-hover-color: #b03b00;
  --light-mode-syn-icon-color: #dd0888;
  --light-mode-syn-hover-color: #99065e;
  --light-mode-preview-bb-url-color: #3e8629;
  --light-mode-genaiwarning-text-color: #b455aa;
}

.modal-fade {
  animation: fadeIn 0.2s ease-in both;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.form-control::placeholder {
  color: var(--light-mode-text-color);
}
.required:after {
  content: " *";
  color: var(--light-mode-red-01-color);
}
.label-text {
  size: 16px;
  font-family: Graphik;
  font-weight: 600;
  color: var(--light-mode-text-color);
  margin-bottom: 2px;
  text-align: -webkit-auto;
  margin-left: 0.5em;
}
select {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%2336313b' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px; /*GREY ARROWS*/
}
.purple-borders {
  border: 2px solid var(--light-mode-border-color);
}
.modal-alarm-timer {
  display: flex;
  font-size: large;
  justify-content: center;
}

.modal-alarm-timer svg {
  place-self: center;
}
.modal-alarm-timer p {
  font-weight: 700;
  font-size: larger;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.modaltext-center,
.modaltext-center .modal-header {
  text-align: center;
  justify-content: center;
}

.modal-button {
  width: auto;
  height: 2.5em;
  font-weight: bold;
}
.modal-button-second {
  width: 6em;
  height: 2.5em;
  font-weight: bold;
}
.modal-content {
  white-space: break-spaces;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .modal-content {
    width: 87%;
    position: relative;
    left: 3em;
  }
}

.form-control.is-invalid {
  border: 1.5px solid var(--light-mode-invalid-feedback);
}

.btn:disabled {
  background: var(--light-mode-light-grey-color);
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
}
.btn-gray-class,
.form-bb .btn:first-child:active,
.btn-gray-class:hover,
.btn-gray-class:focus {
  background: var(--light-mode-light-grey-color);
  border: 2px solid var(--light-comment-tracker-date);
  color: var(--light-comment-tracker-date);
  cursor: default;
  opacity: 0.65;
  font-weight: bold;
}
.btn-primary:hover {
  background-color: var(--dark-mode-primary-button-hover-color);
}
/* override from bootstrap class, to add the arrows for the select element */
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' fill='%23a100ff' viewBox='0 0 4 5'%3e%3cpath d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    /*PURPLE ARROWS*/ #000 no-repeat center right 1.75rem /
      calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #a100ff;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.home-menu-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: calc(100vh - 130px);
  margin-top: -16px;
}
.home-menu-outlet-container {
  top: 0;
  border-left: 3px solid var(--light-mode-icon-color);
  height: 100%;
  position: relative;
}

.vertical-separator {
  width: 0.3%;
  height: 50em;
  background: var(--light-mode-icon-color);
  position: absolute;
  left: 21%;
  top: 4.7em;
}

.double-arrow-menu-div {
  background-color: var(--light-mode-icon-color);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  width: 1.5em;
  height: 1.5em;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 22em;
  left: calc(var(--sidemenu-width) + -9px);
}
.double-arrow-menu-icon {
  fill: var(--light-mode-white-color);
}

.overlay-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 12;
  cursor: pointer;
  align-items: center;
  text-align: center;
  display: grid;
}

.toast-z-index {
  z-index: 99999;
}

/*-------------------------------DARK MODE SECTION--------------------------------*/

.darkMode {
  background-color: var(--dark-mode-background-color);
}

.darkMode .overlay-spinner {
  background-color: rgba(0, 0, 0, 0.8);
}

.darkMode .form-control {
  background-color: var(--dark-mode-link-button-bg-color);
  border: 1.5px solid var(--dark-mode-border-color);
  color: var(--dark-mode-text-color);
}
.darkMode .form-control.is-valid {
  border: 1.5px solid var(--dark-mode-light-green-alert-color);
}

.darkMode .form-control.is-invalid {
  border-color: var(--dark-mode-invalid-feedback);
}

.darkMode select {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' fill='%23a100ff' viewBox='0 0 4 5'%3e%3cpath d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    /*PURPLE ARROWS*/ #000 no-repeat center right 1.75rem /
      calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.modal-content.dark-content {
  background-color: var(--dark-mode-modal-background-color);
  color: var(--dark-mode-text-color);
  border: 1px solid var(--dark-mode-modal-border);
}
.api-failure-message {
  display: flex;
  justify-content: center;
  font-size: large;
  font-family: "Graphik";
  margin-top: 10px;
  color: var(--light-mode-text-color);
}
.spinner-delegates {
  position: relative;
  top: 5px;
}
.darkMode .spinner-delegates {
  color: var(--dark-mode-text-color);
}
.darkMode .api-failure-message {
  color: var(--dark-mode-text-color);
}
.darkMode .form-control::placeholder {
  color: var(--dark-mode-text-color);
}

.darkMode select {
  color: var(--dark-mode-text-color);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' fill='%23a100ff' viewBox='0 0 4 5'%3e%3cpath d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    /*PURPLE ARROWS*/ var(--dark-mode-link-button-bg-color) no-repeat center
      right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.darkMode label {
  color: var(--dark-mode-text-color);
}

.darkMode .btn:disabled {
  background: var(--dark-mode-secondary-button-bg-color);
  border: 2px solid var(--dark-mode-btn-secondary-disabled-border-color);
  color: var(--dark-mode-btn-secondary-disabled-color);
  cursor: default;
}

.darkMode .btn.btn-success:hover {
  background-color: var(--dark-mode-green-pill-hover);
}

.darkMode .btn.btn-danger:hover {
  color: var(--light-mode-white-color);
  background-color: var(--red-pills-hover);
  border-color: var(--red-pills-hover);
}

.darkMode .text-mute {
  color: var(--dark-mode-gray-text-color);
}
.darkMode .purple-borders {
  border: 2px solid var(--dark-mode-border-color);
}
/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #96968c;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.darkMode ::-webkit-scrollbar-thumb {
  background: var(--dark-mode-text-color);
}
