.metrics-container {
  display: flex;
  margin-bottom: 50%;
  margin-left: 5.1%;
  margin-right: 8%;
}

.metrics {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
}
.metrics-options {
  display: flex;
  row-gap: 7px;
  margin-top: 15px;
  flex-direction: column;
  margin-left: 1em;
}
.loading-message {
  margin-top: 10px;
  font-size: 22px;
}
.spinner-border {
  width: 35px;
  height: 35px;
}
.darkMode .loading-message {
  color: var(--dark-mode-text-color);
}
.required:after {
  content: " *";
  color: var(--light-mode-red-01-color);
}

.date-picker-and-calendar-btn-div {
  display: flex;
}

.info-icon-space-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info-icon-space {
  margin-right: 5px;
}

.from-date,
.to-date {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 1em;
  align-items: flex-start;
  justify-content: center;
}
.label-date {
  margin-right: 10px;
}
.metrics-date {
  width: 132px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  caret-color: transparent;
}
.metric-option {
  margin-right: 25px;
}
.metrics-section-title {
  display: flex;
  justify-content: center;
  font-family: "Graphik";
  margin-bottom: 15px;
}
.metrics-tabs {
  display: flex;
  flex-direction: column;
  font-family: "Graphik";
  color: var(--light-mode-promote-nav-links);
  font-size: 15px;
  white-space: nowrap;
  justify-content: space-evenly;
  margin-bottom: 25px;
  margin-top: 25px;
  column-count: 3;
  column-gap: 35px;
  margin-left: 8%;
  margin-right: 8%;
}
.tabs {
  display: flex;
  flex-direction: row;
  border: none;
  background-color: transparent;
  color: var(--light-mode-link-color-normal);
  font-size: 1.1em;
  justify-content: flex-start;
}

.metrics-st-section-tabs-separator {
  width: 3em;
  height: 1em;
}

.metrics-st-menu-section-separator {
  position: relative;
  right: 2em;
  height: 1.5em;
  border-bottom: 3px solid var(--light-mode-light-grey-color);
}

.active-submitted-request {
  color: var(--light-mode-text-color);
  border-bottom: 3px solid var(--light-mode-bb-icon-color);
}

.alert-metrics {
  background-color: var(--light-mode-header-box-shadow);
  color: var(--light-mode-white-text);
  font-weight: bold;
  display: flex;
  justify-content: center;
  height: 50px;
  font-size: 19px;
  align-items: center;
}

.metric-section {
  margin-top: 30px;
  font-weight: bold;
}

.calendar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.get-metrics {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}
.get-metrics-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  width: 10em;
  border-color: var(--light-mode-secondary-button-color);
  background-color: var(--light-mode-secondary-button-color);
  color: var(--light-mode-white-text);
}
.get-metrics-button:active {
  background-color: var(--light-mode-button-outline-bg-color);
}
.get-metrics:disabled {
  background: var(--light-mode-light-grey-color);
  border-style: none;
  color: var(--light-mode-white-text);
}
.get-metrics-button:hover {
  background-color: var(--light-mode-button-outline-base-color);
  color: var(--light-mode-white-text);
}

.darkMode .get-metrics-button:hover {
  background-color: var(--dark-mode-btn-primary-color-hover);
}

.options_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.options_menu {
  margin-bottom: 20px;
  margin-left: 6.5%;
  display: flex;
}
.metrics-menu {
  display: flex;
  flex-wrap: wrap;
  width: 160px;
  background-color: var(--light-mode-header-box-shadow);
  align-items: center;
  justify-content: center;
}
.options_menu .menuBtns {
  border: 2px solid var(--light-mode-button-outline-base-color);
}
.metric-menu-option {
  border: groove 3px;
  border-radius: 0.45rem;
  border-color: #80808054;
}

.metrics-menu-options {
  width: 200px;
  top: 120px;
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.metrics-tables-container {
  display: flex;
}

.charts_box {
  margin-left: 3em;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 43em;
  height: 27em;
  overflow-x: auto;
  overflow-y: auto;
}
.charts_box .charts {
  margin-bottom: 30px;
}
.menuBtns:focus {
  border: 2px solid var(--light-mode-page-item-background-active-color);
  box-shadow: 0 0 0 0.2rem var(--light-mode-page-item-background-active-color);
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-content.show {
  display: flex;
  flex-direction: row;
}

.metrics-side-menu-select-options {
  display: flex;
  color: var(--light-mode-text-color);
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
}
/* .metrics-items {
} */
.darkMode .metrics-items {
  color: var(--dark-mode-text-color);
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

a.dropdown-item.metric-menu-option:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--light-mode-button-outline-bg-color);
}

.darkMode .dropdown-content a {
  background-color: var(--dark-mode-modal-background-color);
  color: var(--dark-mode-text-color);
}

.calendar-icon {
  margin-top: -5px;
  width: 20px;
  height: 20px;
}

.form-check-input:checked {
  background-color: var(--light-mode-button-outline-base-color);
  border-color: var(--light-mode-border-color);
}

.loading-overlay {
  position: absolute;
  bottom: 1em;
  right: -14em;
  height: 55em;
  width: 115vw;
  background-color: rgb(0 0 0 / 20%);
  z-index: 3;
}
.loading-spinner-main-div {
  position: relative;
  top: 12em;
  z-index: 14;
}

.url-table-metrics {
  display: flex;
  flex-direction: column;
}
.table-metrics-container {
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 30px;
  border-top: 1px solid var(--light-mode-header-box-shadow);
  border-bottom: 1px solid var(--light-mode-header-box-shadow);
  border-right: 1px solid var(--light-mode-header-box-shadow);
}
.urls-tables-metrics-headers {
  display: "flex";
  flex-direction: row;
  align-items: "center";
  background-color: "#E7E7ED";
  padding: "8px";
  margin-bottom: "15px";
}
.table {
  margin-bottom: 0;
}
.custom-thead {
  background-color: var(--light-mode-header-box-shadow);
  border-bottom: var(--light-mode-header-box-shadow);
  position: sticky;
  top: 0;
  border-right: 1px solid var(--light-mode-header-box-shadow);
  border-left: 1px solid var(--light-mode-header-box-shadow);
}

.darkMode .custom-thead {
  background-color: var(--light-mode-header-box-shadow);
  border-bottom: var(--light-mode-header-box-shadow);
  border-color: var(--light-mode-button-outline-base-color);
}

.total-col-heading {
  text-align: center;
  margin-bottom: 0;
}
.date-col-heading {
  text-align: center;
  flex: 1;
  margin-bottom: 0;
}

.custom-thead th {
  color: var(--light-mode-white-text);
  text-align: center;
}
.darkMode .custom-thead th {
  color: var(--light-mode-white-text);
}

.custom-td {
  text-align: center;
  border: 1px solid var(--light-mode-button-outline-base-color);
  max-width: 20ch;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: 16px "Graphik";
}
.metrics-heading-table {
  top: 0;
  position: sticky;
  background-color: var(--light-mode-header-box-shadow);
  border-bottom: var(--light-mode-header-box-shadow);
}
.darkMode .metrics-heading-table {
  background-color: var(--light-mode-header-box-shadow);
  border-bottom: var(--light-mode-header-box-shadow);
}
.text-metrics-url-heading {
  color: var(--light-mode-white-text);
}

.td-metrics-table {
  border: 1px solid var(--light-mode-button-outline-base-color);
}

.darkMode .options_menu {
  color: var(--dark-mode-feedback-text-color);
}
.custom-disabled {
  pointer-events: none;
  opacity: 1;
  cursor: not-allowed;
}

.download-csv-report-btn {
  background: transparent;
  border: none;
}

.download-csv-report-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.download-csv-report-btn.csv-icon-dark > svg > path {
  stroke: white;
}

.feedback-metrics {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 8%;
  margin-left: 8%;
  padding-bottom: 140px;
  align-items: center;
}

.feedback-menu-div {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
}

.feedback-chart-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.nav-link {
  font-family: "Graphik";
  color: var(--light-mode-link-color-normal);
  font-size: 16px;
  border-bottom: 3px;
}

.nav-link.active {
  font-family: "Graphik";
  color: var(--light-mode-text-color);
  font-size: 16px;
  border-bottom: 3px;
  border-bottom-style: solid;
  border-color: var(--light-mode-promote-nav-links);
}

.nav-link.menu-tab.active-menu-tab {
  font-family: "Graphik";
  color: var(--light-mode-text-color);
  font-size: 16px;
  font-weight: 700;
  border-bottom: 3px;
  border-bottom-style: solid;
  border-color: var(--light-mode-promote-nav-links);
}

.select-date {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 17px;
  margin-top: 17px;
  margin-bottom: 25px;
}
.pie-container {
  display: flex;
  justify-content: center;
  margin-top: 4em;
}
.no_data_msg {
  font-family: "Graphik";
  font-size: 17px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.feedback-metrics-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: var(--light-mode-secondary-button-color);
  background-color: var(--light-mode-secondary-button-color);
  color: var(--light-mode-white-text);
}
.export-feedback:hover,
.feedback-metrics-btn:hover {
  color: var(--light-mode-white-text);
  background-color: var(--light-mode-button-outline-base-color);
}
.darkMode .export-feedback:hover,
.darkMode .feedback-metrics-btn:hover {
  color: var(--light-mode-white-text);
  background-color: var(--dark-mode-btn-primary-color-hover);
}
.pie-chart {
  margin: 8px;
  width: 20em;
  height: 20em;
  display: flex;
}
.get-feedback-btn {
  display: flex;
  justify-content: center;
  padding-left: 1.8em;
}
.export-feedback-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;
}
.export-feedback {
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: var(--light-mode-secondary-button-color);
  background-color: var(--light-mode-secondary-button-color);
  color: var(--light-mode-white-text);
}
#usage_link,
#feedback_link {
  font-family: "Graphik";
  color: var(--light-mode-link-color-normal);
  border-bottom-color: transparent;
}

.pie-chart-title {
  font-size: 20px;
}

.btn-metrics {
  border-radius: 0.6em;
  font-family: "Graphik";
  font-size: 17px;
  font-weight: 700;
  height: 50px;
  white-space: nowrap;
  width: 8em;
}

.added-width {
  width: 11em;
}

.metrics-checkbox-label-text {
  size: 16px;
  font-family: Graphik;
  color: var(--light-mode-text-color);
  margin-bottom: 2px;
  text-align: -webkit-auto;
  margin-left: 3px;
  white-space: nowrap;
}

.checkbox-selected-bold {
  font-weight: 700;
}

.darkMode #usage_link,
.darkMode #feedback_link {
  color: var(--dark-mode-link-color-normal);
}
#feedback_link.active,
#usage_link.active {
  color: var(--light-mode-text-color);
  border-bottom-color: var(--light-mode-link-color-visited);
}

#feedback_link:hover,
#usage_link:hover {
  color: var(--light-mode-icon-color);
}

.darkMode #feedback_link.active,
.darkMode #usage_link.active {
  color: var(--dark-mode-text-color);
  border-bottom-color: var(--dark-mode-links-inactive-promote);
}
.darkMode #feedback_link:hover,
.darkMode #usage_link:hover {
  color: var(--dark-mode-link-color-hover);
}
.darkMode .no_data_msg {
  color: var(--dark-mode-text-color);
}

.darkMode .nav-link.active {
  color: var(--dark-mode-text-color-active-tab);
}
.darkMode .nav-link.menu-tab.active-menu-tab {
  color: var(--dark-mode-text-color-active-tab);
}
