.main_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 15em;
}

.triangle_div {
  background-image: linear-gradient(to bottom right, transparent 54%, yellow 0),
    linear-gradient(to top right, yellow 44%, transparent 0);
  background-size: 50% 74%;
  background-repeat: no-repeat;
  background-position: left, right;
}
.not-authorized-page-message {
  color: red;
}
.darkMode .main_div {
  color: var(--dark-mode-text-color);
}
