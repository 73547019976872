.ht_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Graphik";
  margin-top: 30px;
  padding-bottom: 120px;
}

.control_group {
  width: 650px;
}
.ht_location_drop,
.ht_refiner,
.ht_location_drop {
  margin-bottom: 2px;
}
.ht_field_plus,
.ht_steps {
  display: flex;
  flex-direction: row;
}

.tooltip {
  margin-right: 10px;
  margin-bottom: 2px;
}

.steps_title_msg {
  margin-bottom: 9px;
  margin-top: 5px;
  color: var(--light-mode-red-color-warning);
  font-weight: bold;
}

.ht_steps {
  margin-bottom: 15px;
}
.ht_steps,
.steps_icons {
  flex-direction: row;
}

.input_step {
  margin-bottom: 16px;
}

.input_step,
.step_htIcons {
  display: flex;
  flex-direction: row;
}

.step_htIcons {
  display: flex;
  margin-right: -137px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}
#step-item {
  margin-top: -15px;
}

.steps_section {
  display: flex;
  flex-direction: column;
}
.btn-icon-container {
  width: 25px;
  height: 25px;
  border-style: none;
  background-color: transparent;
  margin-right: 6px;
  padding-left: 0;
  padding-top: 0;
}
.btn-icon-container:focus-visible {
  position: inherit;
  padding-left: 0;
  padding-top: 0;
}

.arrow_up,
.arrow_down,
.trash_icon,
.plus_icon {
  width: 25px;
  height: 25px;
  margin-right: 6px;
  color: var(--light-mode-icon-color);
}

.arrow_up:hover,
.arrow_down:hover,
.trash_icon:hover,
.plus_icon:hover {
  color: var(--light-mode-border-header-bb);
}

.ht_label {
  margin-bottom: 0.3em;
  font-weight: bold;
  margin-left: 7px;
}

.kw_del_ht_field {
  display: -webkit-box;
  flex-direction: row;
}

.plus_icon_kw_ht_div,
.plus_icon_del_ht_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-left: 11px;
  color: var(--light-mode-icon-color);
  margin-top: 6px;
}
.plus_icon_kw_ht,
.plus_icon_del_ht {
  fill: var(--purple_01);
}
.plus_icon_kw_ht:hover,
.plus_icon_del_ht:hover {
  fill: var(--light-mode-green-01-color);
  cursor: pointer;
}

.icons_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  background-color: transparent;
  width: 25px;
  height: 25px;
}

.ht_tooltip {
  margin-bottom: 3px;
  width: 20px;
  height: 20px;
}

.ht_tooltip_steps {
  margin-bottom: -21px;
  width: 20px;
  height: 20px;
}

.ht_label_steps {
  margin-left: 26px;
}
.required:after {
  content: " *";
  color: var(--light-mode-red-01-color);
}

.ht_pills {
  margin: 5px 5px 0 0;
  background-color: var(--light-mode-hover-icons);
  border-style: none;
}

.pills {
  background-color: var(--light-mode-pills);
  border-style: none;
  color: var(--light-mode-text-color);
  font-weight: bold;
}
.pills:hover {
  background-color: var(--light-mode-pills-hover);
  color: var(--light-mode-text-color);
}

.pills:focus {
  box-shadow: 0 0 0 0.2rem var(--light-mode-pills-hover);
  border-color: #64ff50;
}
.how-to-buttons {
  width: 200px;
  height: 50px;
  border-radius: 0.6em;
  font-family: "Graphik";
  font-size: 18px;
  font-weight: 700;
  border: 2px solid;
}
.ht_btns_group {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
}

.ht_primaryBtn,
.ht_secondaryBtn {
  width: 200px;
  height: 50px;
  border-radius: 0.6em;
  font-family: "Graphik";
  font-size: 18px;
  font-weight: 700;
}

.ht_primaryBtn {
  background-color: var(--light-mode-btn-info-color);
  border-style: none;
  color: var(--light-mode-white-text);
}

.ht_primaryBtn:hover {
  background-color: var(--light-mode-secondary-button-hover-color);
}
.primary-btn-colour:disabled {
  background-color: var(--light-mode-btn-info-color);
  border-style: none;
  color: var(--light-mode-white-text);
}
#ht_form .btn-secondary {
  background: transparent;
  border: 2px solid var(--light-mode-btn-info-color);
  color: var(--light-mode-btn-info-color);
}
#ht_form .btn-secondary:hover {
  background-color: var(--light-mode-button-hover-color);
  color: var(--light-mode-white-color);
}

#ht_form .btn-secondary:disabled {
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-light-grey-color);
}
.darkMode #ht_form .btn-secondary:disabled {
  background: var(--dark-mode-secondary-button-bg-color);
  border: 2px var(--dark-mode-btn-secondary-disabled-border-color);
  color: var(--dark-mode-btn-secondary-disabled-color);
}
.darkMode #ht_form .btn-secondary {
  border: 2px solid var(--dark-mode-secondary-button-border-color);
  color: var(--dark-mode-base-btn-text-color);
}
.darkMode #ht_form .btn-secondary {
  background: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  color: var(--dark-mode-text-color);
}
#compare-button {
  background: transparent;
  border: 2px solid var(--light-mode-secondary-button-color);
  color: var(--light-mode-secondary-button-text-color);
}
#compare-button:hover {
  background-color: var(--light-mode-button-hover-color);
  color: var(--light-mode-white-color);
}
#compare-button:disabled {
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-light-grey-color);
}
.darkMode #compare-button {
  border: 2px solid var(--dark-mode-secondary-button-border-color);
  color: var(--dark-mode-base-btn-text-color);
}
.darkMode #compare-button:hover {
  background-color: var(--dark-mode-secondary-button-color);
  border: 2px solid var(--light-mode-button-hover-color);
  color: var(--light-mode-white-color);
}
.ht_primaryBtn:active {
  border: 2px solid var(--light-mode-btn-info-color);
  box-shadow: 0 0 0 0.2rem rgb(70 0 115 / 50%);
  background-color: var(--light-mode-active-btn-primary-forms);
}

.ht_primaryBtn::after {
  background-color: var(--light-mode-btn-info-color);
}

.ht_secondaryBtn {
  background-color: transparent;
  color: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-secondary-button-color);
}

.ht_secondaryBtn:hover {
  background-color: var(--light-mode-secondary-button-hover-color);
  border-style: none;
  color: var(--light-mode-white-text);
}
.ht_secondaryBtn:focus {
  background-color: transparent;
  color: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
}

.error_msg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invalid {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--light-mode-red-01-color);
}
.ht-request-info-title-div {
  color: var(--light-mode-white-text);
  background-color: var(--light-mode-ht-icon-color);
  border-radius: 0.2em;
  border: 1px solid var(--light-mode-ht-icon-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
}

.not_auth_div {
  position: relative;
  top: -9em;
}

.invalid_char_ht {
  display: flex;
  justify-content: center;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--light-mode-red-01-color);
}

.responsive-form-css {
  margin-block: 1.7rem;
  padding-inline: 7rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  font-family: Graphik;
}

/* DARK MODE */

.darkMode .ht_secondaryBtn {
  color: var(--light-mode-red-01-color);
  border-color: var(--dark-mode-text-color);
}

.darkMode .ht_primaryBtn:hover {
  background-color: var(--dark-mode-btn-primary-color-hover);
  border: 2px solid var(--light-mode-button-outline-base-color);
}
.darkMode #ht-cancel-request-button:hover {
  order: 2px solid var(--dark-mode-red-color-warning);
  background: var(--dark-mode-red-color-warning);
  color: var(--dark-mode-dark-text);
}
.darkMode #ht-reject-button:hover {
  border: 2px solid var(--dark-mode-red-color-warning);
  background: var(--dark-mode-red-color-warning);
  color: var(--dark-mode-dark-text);
}
.ht-tooltip-dark {
  background-color: var(--dark-mode-modal-border);
  border-radius: 5%;
  padding: 2px;
}
.darkMode .ht-request-info-title-div {
  background: var(--dark-mode-ht-icon-color);
  color: var(--dark-mode-dark-text);
}
