.triangle_div {
  background-image: linear-gradient(to bottom right, transparent 54%, yellow 0),
    linear-gradient(to top right, yellow 44%, transparent 0);
  background-size: 50% 74%;
  background-repeat: no-repeat;
  background-position: left, right;
  height: 100px;
}
.mantainance {
  font-size: -webkit-xxx-large;
}
.date {
  font-weight: bold;
}

.maintenance-page {
  text-align-last: center;
  padding: 36px;
}

.darkMode .mantainance {
  color: var(--dark-mode-text-color);
}
