.ctc_main_container_div {
  padding-left: 10em;
  padding-right: 9em;
  margin-top: 1.5em;
  min-height: 145vh;
  max-height: 300vh;
}

.info_section_div {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  font-weight: 630;
}

.input_label {
  margin-left: 0.5em;
}

.section_separator {
  margin: 0.4em;
}

.selected_refiners_div,
.selected_locations_div,
.selected_keywords_div,
.selected_delegates_div {
  display: flex;
  flex-wrap: wrap;
}

.ctc_form_buttons_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 10em;
}

.pills_style {
  margin-right: 1em;
  margin-top: 0.5em;
}

.input_and_button_div {
  display: flex;
  align-items: center;
}

.plus_icon_css {
  margin-left: 1em;
}

.btn_font_weigth {
  font-weight: 600;
}

.hidden_delegate_div {
  width: 0em;
  height: 0em;
  display: none;
}

.adjust_error_msg {
  position: relative;
  text-align: center;
}
.adjust_error_msg_description {
  display: flex;
  position: relative;
  justify-content: center;
}

.font-size-dot-one {
  font-size: 1.1em;
}

.ctc_request_info_title_div {
  color: var(--light-mode-white-text);
  background-color: var(--light-mode-ctc-icon-color);
  border-radius: 0.2em;
  border: 1px solid var(--light-mode-ctc-header-border-color-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
}

.ctc_input_kw_add_btn {
  fill: var(--purple_01);
}
.ctc_input_kw_add_btn:hover {
  fill: var(--light-mode-green-01-color);
  cursor: pointer;
}
.ctc_input_delegate_add_btn {
  fill: var(--purple_01);
}
.ctc_input_delegate_add_btn:hover {
  fill: var(--light-mode-green-01-color);
  cursor: pointer;
}

.form-margin-bottom {
  margin-bottom: 8em;
}

/* dark mode section */

.darkMode_tooltip {
  background-color: var(--dark-mode-modal-border);
  border-radius: 5%;
  padding: 4px;
}
.modal-ctc-size {
  width: 368px;
}

.info_section_div_dark {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  font-weight: 630;
  color: var(--dark-mode-text-color);
}

.darkMode .ctc_request_info_title_div {
  background: var(--dark-mode-ctc-icon-color);
  color: var(--light-mode-text-color);
}

.darkMode #ctc-save-changes-btn {
  border-color: var(--dark-mode-gray-text-color);
  color: var(--dark-mode-text-color);
}

.darkMode #ctc-save-changes-btn:disabled {
  color: var(--dark-mode-dark-text);
}
