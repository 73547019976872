.rights-text {
  float: right;
  margin-right: 1%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.footer-bottom {
  border-top: 1.5px solid var(--light-mode-border-color);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: var(--light-mode-footer-bg-color);
  font-size: 0.9rem;
  z-index: 10;
  color: var(--light-mode-text-color);
}
.search-tools-footer-links {
  color: var(--light-mode-link-color-normal);
  background: transparent;
  font-weight: bold;
  float: left;
  margin-left: 1%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.search-tools-footer-links:hover {
  color: var(--light-mode-link-color-hover);
  cursor: pointer;
  text-decoration: underline;
}

.search-tools-footer-links:visited {
  color: var(--light-mode-link-color-visited);
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: var(--light-mode-text-color);
  font-family: "Graphik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 55px;
}
.footer::-webkit-scrollbar {
  width: 5px;
}
.footer::-webkit-scrollbar-thumb {
  background-color: #96968c;
  border-radius: 5px;
}
a {
  text-decoration: none;
}
@font-face {
  font-family: "Graphik";
  src: url("/src/assets/fonts/Graphik-Black-Web.eot");
  src: url("/src/assets/fonts/Graphik-Black-Web.eot?#iefix")
      format("embedded-opentype"),
    url("/src/assets/fonts/Graphik-Regular-Web.woff2") format("woff2"),
    url("/src/assets/fonts/Graphik-Regular-Web.woff") format("woff"),
    url("/src/assets/fonts/Graphik-Regular.ttf") format("truetype");
}
/* Dark Mode*/
.darkMode .footer {
  color: var(--dark-mode-text-color);
}
.darkMode .footer-bottom {
  border-top: 1.5px solid var(--dark-mode-border-color);
  background-color: var(--dark-mode-footer-bg-color);
  color: var(--dark-mode-text-color);
}
.darkMode .search-tools-footer-links {
  color: var(--dark-mode-link-color-normal);
}
.darkMode .search-tools-footer-links:hover {
  color: var(--dark-mode-link-color-hover);
}

.darkMode .search-tools-footer-links:visited {
  color: var(--dark-mode-link-color-visited);
}
