.UpperBody {
  overflow-wrap: break-word;
}

/* Dark Mode */
.darkMode .Title {
  color: var(--dark-mode-text-color);
}
.darkMode .BodyLink {
  color: var(--dark-mode-link-color-normal);
}
.darkMode .UpperBody {
  color: var(--dark-mode-text-color);
}
.darkMode .MainTitleURL {
  color: var(--dark-mode-link-color-normal);
}
.darkMode #MainTitle {
  color: var(--dark-mode-link-color-normal);
}
.darkMode #MainTitle_TC_id {
  color: var(--dark-mode-title-color-purple);
}
.darkMode .BodyLink:hover {
  color: var(--dark-mode-link-color-hover);
  text-decoration: underline;
}
.darkMode .BodyRole {
  color: var(--dark-mode-text-color);
}
.darkMode .BodyNoLink,
.darkMode .LowerBodyPeopleNoRole {
  color: var(--dark-mode-text-color);
}
