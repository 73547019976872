.zindex-11 {
  z-index: 11;
}

.gen-ai-rocket-tooltip .tooltip-inner {
  background-color: white;
  color: var(--light-mode-text-color);
  border: 1px solid black;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.8);
}
.gen-ai-rocket-tooltip.bs-tooltip-auto[data-popper-placement^="right"]
  .tooltip-arrow::before {
  border-right-color: white;
}
.gen-ai-rocket-tooltip.bs-tooltip-auto[data-popper-placement^="top"]
  .tooltip-arrow::before {
  border-top-color: white;
}
.gen-ai-rocket-tooltip.bs-tooltip-auto[data-popper-placement^="left"]
  .tooltip-arrow::before {
  border-left-color: white;
}
.gen-ai-rocket-tooltip.bs-tooltip-auto[data-popper-placement^="left"]
  .tooltip-inner {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
}
.gen-ai-rocket-tooltip.bs-tooltip-auto[data-popper-placement^="right"]
  .tooltip-inner::after {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height + 1px);
  height: var(--bs-tooltip-arrow-width);
  border-right-color: black;
}

.gen-ai-rocket-tooltip .summarize-description {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}
.gen-ai-rocket-tooltip .tooltip-header {
  margin: 7px;
  margin-left: 5px;
}
.gen-ai-rocket-tooltip .tooltip-info {
  font-size: smaller;
}
.gen-ai-rocket-button {
  background-color: transparent;
  border: none;
  width: 0;
  margin-left: 0.7em;
}
.genai-loading-spinner {
  position: absolute;
  left: calc(var(--bestbet-field-width) * 1.03);
}

.description-modal {
  max-width: 800px;
}

.description-modal.success {
  max-width: calc(var(--bestbet-field-width));
  margin-top: 10%;
}

.success-icon {
  margin-top: 2%;
  margin-bottom: 5%;
}
.description-modal .modal-header h5 {
  font-weight: bolder;
}
.user-input {
  display: flex;
  align-items: center;
}

.form-bb .user-requirements.form-control {
  width: -webkit-fill-available;
  margin-bottom: auto;
}

.send-arrow {
  position: absolute;
  background-color: white;
  border: unset;
  right: 0;
  margin-right: 17px;
}

.description-modal .modal-button,
.description-modal .modal-header button {
  background-color: transparent;
  border: unset;
}

.description-modal .modal-button-second {
  background-color: var(--purple_01);
  border-radius: 30px;
  color: var(--light-mode-white-text);
  border: unset;
}

.generating {
  max-width: 500px;
}

.disclaimer {
  margin: 5px -5px 8px;
  font-style: italic;
  outline: none;
  font-size: 0.8em;
  margin-left: 5px;
}

.no-regenerate {
  color: var(--light-mode-invalid-feedback);
  font-style: italic;
}

.success-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.show-rocket-button-position {
  position: relative;
  left: 0.7em;
}

.div-url-and-gen-ai-container {
  margin-top: -2.5em;
  margin-bottom: -2.5em;
}
.div-url-and-gen-ai-tc-container {
  margin-bottom: -7em;
}

.gen-ai-options-menu {
 
  position: relative;
  top: 6em;
  left: -0.7em;
  background-color: white;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  padding: 1em;
  border-radius: 5px 5px 5px 5px;
}



.show-genai-options-position {
  position: relative;
  left: 7.8em;
}
.show-genai-tc-options-position {
  position: relative;
  top: 3em;
}

.div-radiobtn-and-label {
  padding: 5px;
  display: flex;
  align-items: center;
}

.gen-ai-option-radio-btn {
  accent-color: var(--light-mode-spinner-color);
  width: 1em;
  height: 1em;
}

.gen-ai-options-separator-1 {
  height: 0.3em;
  margin-bottom: 0.5em;
}
.gen-ai-options-separator-2 {
  position: relative;
  height: 0.2em;
  top: 3.3em;
  left: -0.7em;
  margin-bottom: 0.5em;
  background-color: rgb(0 0 0 / 10%);
  z-index: 33;
}

.gen-ai-options-separator-2-tc {
  position: relative;
  height: 0.2em;
  top: 0.3em;
  left: -0.7em;
  margin-bottom: 0.5em;
  background-color: rgb(0 0 0 / 10%);
  z-index: 33;
}

.gen-ai-options-separator-top2 {
  position: relative;
  height: 0.2em;
  top: -3.2em;
  left: -0.7em;
  margin-bottom: -3.5em;
  background-color: rgb(0 0 0 / 10%);
  z-index: 33;
}

.gen-ai-options-separator-top2-tc {
  position: relative;
  height: 0.2em;
  top: -6.2em;
  left: -0.7em;
  margin-bottom: -3.5em;
  background-color: rgb(0 0 0 / 10%);
  z-index: 33;
}

.genai-button-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: 0.5em;
}

.genai-button-div-top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: -0.3em;
  margin-bottom: 10px;
}

.gen-ai-options-btn {
  background-color: transparent;
  border: none;
  color: var(--light-mode-secondary-button-color);
  font-weight: 700;
}

@media (min-width: 768px) and (max-width: 768px) {
  .show-genai-options-position {
    left: 5.2em;
  }
  .div-url-and-gen-ai-container {
    margin-top: -3.5em;
    margin-bottom: -3.5em;
  }
}

@media  (max-width: 1024px) {
  .show-genai-options-position {
    left: 5.2em;
  }
  .div-url-and-gen-ai-container {
    margin-top: -3.5em;
    margin-bottom: -3.5em;
  }
  .gen-ai-options-menu{
    left: -13.5rem;
        width: 100%;
      label { 
        text-align: left;
        margin-left: 5%;    
      }
      input {
        width: 12%;
      }

      
    }

  .gen-ai-options-separator-top2 {
    top: -6.2em !important;
    width: 100%;
    left: -13.5rem;
  }
  .gen-ai-options-separator-2{
    left: -13.5rem;
    width: 100%;
  }
}

.modal-dialog {
  z-index: 99999;
}

/* DARK MODE */
.darkBg .gen-ai-rocket-tooltip .tooltip-inner {
  background-color: var(--dark-mode-background-color);
  color: var(--dark-mode-text-color);
  border: 1px solid var(--dark-mode-button-outline-base-color);
  box-shadow: -4px 4px 8px var(--dark-mode-button-outline-base-color);
}
.darkBg
  .gen-ai-rocket-tooltip.bs-tooltip-auto[data-popper-placement^="right"]
  .tooltip-arrow::before {
  border-right-color: var(--dark-mode-background-color);
}
.darkBg
  .gen-ai-rocket-tooltip.bs-tooltip-auto[data-popper-placement^="top"]
  .tooltip-arrow::before {
  border-top-color: var(--dark-mode-background-color);
}
.darkBg
  .gen-ai-rocket-tooltip.bs-tooltip-auto[data-popper-placement^="left"]
  .tooltip-arrow::before {
  border-left-color: var(--dark-mode-background-color);
}
.darkBg
  .gen-ai-rocket-tooltip.bs-tooltip-auto[data-popper-placement^="left"]
  .tooltip-inner {
  box-shadow: 4px 4px 8px var(--dark-mode-button-outline-base-color);
}

.darkBg .description-modal .modal-content.dark-content {
  background-color: var(--dark-mode-background-color);
}

.darkMode .description-modal svg {
  color: var(--dark-mode-text-color);
}
.darkMode .no-regenerate {
  color: var(--dark-mode-invalid-feedback);
  font-style: italic;
}
.darkMode .user-requirements.form-control {
  background-color: var(--dark-mode-background-color);
  border: 1.5px solid var(--dark-mode-desc-modal-pink-color);
  color: var(--dark-mode-text-color);
}
.darkMode .send-arrow {
  background-color: var(--dark-mode-background-color);
}

.darkMode .gen-ai-options-menu {
  /* position: relative;
  top: 6em;
  left: -0.7em; */
  background-color: var(--dark-mode-background-color);
  color: var(--dark-mode-text-color);
  border: 1px solid var(--dark-mode-button-outline-base-color);
  box-shadow: -2px 2px 5px var(--dark-mode-button-outline-base-color);
  padding: 1em;
  border-radius: 5px 5px 5px 5px;
}



.darkMode .gen-ai-options-btn {
  color: var(--dark-mode-text-color);
  font-weight: 700;
}

.darkMode .gen-ai-options-separator-2 {
  background-color: var(--dark-mode-text-color);
  height: 0.01em;
}
.darkMode .gen-ai-options-separator-2-tc {
  background-color: var(--dark-mode-text-color);
  height: 0.01em;
}
.darkMode .gen-ai-options-separator-top2 {
  background-color: var(--dark-mode-text-color);
  height: 0.01em;
}
.darkMode .gen-ai-options-separator-top2-tc {
  background-color: var(--dark-mode-text-color);
  height: 0.01em;
}
