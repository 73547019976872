.preview-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: -33px;
  margin-left: 15px;
  font-size: 18px;
  font-weight: bolder;
  color: var(--light-mode-link-color-normal);
}

.how-to-url {
  margin-top: 1px;
  margin-left: 12px;
  font-size: 13.5px;
}

#Main .preview-steps {
  margin-left: 0;
  margin-top: 7px;
  margin-bottom: 15px;
}

.more-steps {
  color: var(--light-mode-link-color-normal);
  margin-left: 15px;
  margin-bottom: 6px;
  font-size: 14px;
}

/* Dark Mode */

.darkMode .preview-title {
  color: var(--dark-mode-title-color-blue);
}
.darkMode .more-steps {
  color: var(--dark-mode-title-color-blue);
}
.darkMode .preview-title:hover {
  text-decoration: underline;
  color: var(--dark-mode-title-color-hover);
}
.darkMode .more-steps:hover {
  text-decoration: underline;
  color: var(--dark-mode-title-color-hover);
}
.darkMode #MainTitle {
  color: var(--dark-mode-title-color-blue);
}
