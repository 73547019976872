.toast-with-close {
  position: relative;
  right: -30px;
  z-index: 1;
}

.toast-position {
  left: 74%;
  position: fixed;
  z-index: 9999;
  max-width: 25%;
}

.close-toast-button {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  border-style: solid;
  margin-right: 3px;
  border-radius: 10px;
  border-color: transparent;
  background-color: transparent;
}
/* Dark Mode */

.darkMode .close-toast-button {
  color: var(--dark-mode-text-color);
}
.darkMode .toast {
  background-color: var(--dark-mode-autocomplete-results-bg-color);
  color: var(--dark-mode-text-color);
}
