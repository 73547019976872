.switch-label {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  top: 4px;
}

.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-span {
  position: absolute;
  cursor: pointer;
  height: 17px;
  width: 33px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--light-comment-tracker-date);
  transition: 0.3s;
  border-radius: 30px;
}

.toggle-span:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 5px;
  bottom: 3.2px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.switch-input:checked + .toggle-span {
  height: 17px;
  width: 33px;
  background-color: var(--light-mode-icon-color);
}

.switch-input:checked + .toggle-span:before {
  transform: translateX(16px);
}

.switch-strong-el {
  position: absolute;
  left: 100%;
  width: max-content;
  line-height: 30px;
  margin-left: 10px;
  cursor: pointer;
}
