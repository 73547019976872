.acn-logo {
  width: 2em;
  height: 2em;
  background-image: url("../../assets/images/acn_symbol_icon.svg");
  background-repeat: no-repeat;
  margin-top: 2%;
}
.searchtools-logo {
  width: 7em;
  height: 55px;
  background-image: url("../../assets/images/search_icon_banner.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
#MainLogos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 19%;
  min-width: 4em;
  gap: 7%;
  padding: 0%;
}
.searchtools-navbar {
  padding-bottom: 0%;
  margin-right: 1em;
  margin-left: 1em;
}

.filter-options {
  position: fixed;
  background-color: white;
  list-style: none;
  z-index: 10;
}
.searchtools-nav {
  flex-direction: row;
  gap: 3%;
  width: 28%;
}

.searchicon {
  border: 10px solid var(--light-mode-icon-color);
  background-color: var(--light-mode-icon-color);
  background: var(--light-mode-icon-color);
  margin-top: 8px;
  white-space: nowrap;
  cursor: pointer;
  border: 10px solid var(--light-mode-icon-color);
  height: 38px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
header .searchicon:hover {
  background-color: var(--light-mode-searchicon-hover);
  border-color: var(--light-mode-searchicon-hover);
}

.div-filter-by {
  text-align: center;
  vertical-align: middle;
  margin-top: 8px;
  z-index: 1;
}
.btn-filter-by {
  margin: 0;
  height: 38px;
  width: fit-content;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: 700;
  margin-right: 1em;
}

.search-header .nav-item {
  align-self: flex-end;
}

.searchinput {
  max-height: 38px;
  margin-top: 8px;
}
.filter-dropdown,
.option-li {
  padding: 5px;
}

.option-li:hover {
  background-color: var(--light-mode-border-color);
  border-radius: 0.25rem;
  color: var(--dark-mode-text-color);
}

.filter-dropdown {
  display: list-item;
  flex-wrap: wrap;
  border-color: var(--light-mode-border-color);
  border: 0.1em solid var(--light-mode-border-color);
  border-radius: 0em;
  width: 9.5em;
  text-align: left;
}
.searchtools-navbar .navbar-nav .dropdown-menu {
  position: absolute;
}
.search-header .dropdown-item:hover {
  color: var(--light-mode-light-grey-color);
  background-color: var(--light-mode-border-color);
  border-radius: 0.25rem;
}
.search-header .help-icon {
  margin-bottom: 5px;
  height: 40px;
  position: relative;
}
.content-separator {
  margin-top: 1%;
  height: 0.1em;
  border: 1px solid var(--light-mode-icon-color);
  opacity: 1;
}

.search-bar-and-menu-div {
  display: flex;
  align-items: center;
  position: relative;
  right: 2.5em;
}

.stguide-btn-div,
.manage-role-btn-div {
  border: 3.5px solid var(--light-mode-icon-color);
  border-radius: 50%;
}
.manage-role-btn-div {
  margin-right: 1em;
}
.stguide-btn,
.manage-role-btn {
  width: 1.6em;
  height: 1.6em;
  margin: 3px;
  fill: var(--light-mode-icon-color);
}

.stguide-btn-link {
  color: var(--light-mode-icon-color);
}
.stguide-btn-link:hover {
  color: var(--light-mode-icon-color);
}

.header-icons-with-redirect {
  display: flex;
}

.change-input-size {
  width: 150%;
  right: 4em;
}

.hide-role-div {
  display: none;
}
.search-header {
  width: 100%;
}
/*-------------------------DARK MODE SECTION-----------------------------*/

.darkMode .acn-logo {
  background-image: url("../../assets/images/acn_symbol_icon.svg");
}
.darkMode .searchtools-logo {
  background-image: url("../../assets/images/search_icon_banner_dark_mode.svg");
}

.darkMode .search-header .filter-dropdown {
  background-color: var(--dark-mode-link-button-bg-color);
}

.darkMode .search-header .dropdown-item {
  color: var(--dark-mode-text-color);
}
.darkMode .search-header .dropdown-item:hover {
  color: var(--dark-mode-text-color);
  background-color: var(--light-mode-dropdown-item-bg-color);
}

.darkMode header .searchicon {
  background-color: var(--dark-mode-serach-bar-color);
  border: 10px solid var(--dark-mode-serach-bar-color);
}

.darkMode header .searchicon:hover {
  background-color: var(--dark-mode-btn-primary-color-hover);
  border: 10px solid var(--dark-mode-btn-primary-color-hover);
}
