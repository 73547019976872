.preview-bb {
  display: flex;
  flex-direction: column;
  margin: 18px;
}

.preview-title-bb {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: bolder;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
}

.preview-url-bb {
  color: var(--light-mode-preview-bb-url-color);
}

.preview-url-bb {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview-desc-bb {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: small;
}

#title {
  color: var(--light-mode-title-color-blue);
}
#desc {
  color: var(--light-mode-feedback-color);
}
#url {
  color: var(--light-mode-gray-text-color);
}
/*Dark Mode preview */
.darkMode #title {
  color: var(--dark-mode-title-color-blue);
}
.darkMode #desc {
  color: var(--dark-mode-feedback-color);
}
.darkMode #url {
  color: var(--dark-mode-gray-text-color);
}

.darkMode .request-info-block {
  color: var(--dark-mode-title-color-purple);
}

.darkMode .preview-url-bb {
  color: var(--dark-mode-preview-bb-url-color);
}
