.eid-suggestion-list-div {
  position: absolute;
  z-index: 3;
}

.eid-suggestion-results-ul {
  padding: 0;
  margin: 0;
  border: 1px solid var(--light-mode-light-grey-color);
  max-height: 155px;
  overflow: auto;
  width: 87%;
  text-align: left;
  top: 40px;
  left: 15px;
  z-index: 1;
  overflow-x: hidden;
}

.eid-suggestion-results-li {
  padding: 3px 5px 3px 12px;
  color: var(--purple_01);
  background-color: var(--light-mode-white-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.eid-suggestion-results-li.is-active,
.eid-suggestion-results-li:hover {
  background-color: var(--light-mode-title-color-hover);
  color: var(--light-mode-white-color);
  cursor: pointer;
}

/* dark mode */

.darkMode .eid-suggestion-results-ul {
  border: 1px solid var(--purple_01);
}

.darkMode .eid-suggestion-results-li {
  color: var(--dark-mode-text-color);
  background-color: var(--dark-mode-background-color);
}

.darkMode .eid-suggestion-results-li.is-active,
.darkMode .eid-suggestion-results-li:hover {
  background-color: var(--purple_01);
  color: var(--dark-mode-text-color);
  cursor: pointer;
}
