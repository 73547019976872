.synonyms_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3em 10em 3em 10em;
  font-family: "Graphik";
  padding-bottom: 120px;
}

.add_term_field {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 500px;
  margin-bottom: 4em;
}
.add_term_input {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 500px;
  margin-bottom: 4em;
}
.add_synonyms_input {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 500px;
  margin-bottom: 1em;
}
.synonyms-pills-list {
  width: 31em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.synonyms_tooltip {
  margin-right: 5px;
  margin-bottom: 3px;
}
.tooltip-light {
  padding: 0;
}
.synonyms-pills {
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}

.synonyms-pills:focus {
  box-shadow: 0 0 0 0.2rem var(--light-mode-pills-hover);
  border-color: #64ff50;
}

#pill,
#delete-pill {
  font-weight: bold;
}

#pill:hover,
#delete-pill:hover {
  background-color: var(--light-mode-pills-hover);
}

#pill:focus,
#delete-pill:focus {
  box-shadow: 0 0 0 0.2rem var(--light-mode-pills-hover);
  border-color: var(--light-mode-pills);
}

.synonyms_plus_icon {
  width: 24px;
  height: 24px;
  margin-left: 32em;
  margin-top: -2em;
  color: var(--light-mode-icon-color);
  cursor: pointer;
}

.synonyms_plus_icon:hover {
  color: var(--light-mode-border-header-bb);
}
.synonyms_plus_icon:focus-visible {
  color: var(--light-mode-border-header-bb);
}

.synonyms-buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.synonyms-buttons-container button {
  width: 200px;
  height: 50px;
  border-radius: 0.6em;
  font-family: "Graphik";
  font-size: 18px;
  font-weight: 700;
  border: 2px solid;
  margin-top: 3em;
}

.add_synonyms_label,
.add_term_label {
  font-weight: bold;
  margin-bottom: 0.3em;
}
.add_term_label .invalid {
  font-weight: normal;
}

.synonyms_form button.btn-secondary {
  background: transparent;
  border: 2px solid var(--light-mode-secondary-button-color);
  color: var(--light-mode-secondary-button-text-color);
}

.synonyms_form
  .synonyms-buttons-container
  .save-changes-btn:nth-last-child(-n + 1) {
  background: var(--light-mode-secondary-button-color);
  color: white;
}
.synonyms_form .synonyms-buttons-container .save-changes-btn {
  background: transparent;
  border: 2px solid var(--light-mode-secondary-button-color);
  color: var(--light-mode-secondary-button-text-color);
  font-size: 18px;
  font-weight: bold;
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.synonyms_form .synonyms-buttons-container .save-changes-btn:hover {
  background-color: var(--light-mode-button-hover-color);
  color: var(--light-mode-white-color);
}
.remove-btn {
  background-color: transparent;
  border: 2px solid var(--light-mode-red-color-warning);
  color: var(--light-mode-red-color-warning);
}
.remove-btn:hover {
  background-color: var(--light-mode-red-color-warning);
  border: 2px solid var(--light-mode-red-color-warning);
  color: var(--light-mode-red-color-warning);
  color: var(--light-mode-white-text);
}

.synonyms_form .loading-spinner-main-div {
  top: -20vh;
}
.toast-position.autoFillMessage-synonyms {
  top: 20%;
}

.syn-tooltip-dark {
  background-color: var(--dark-mode-modal-border);
  border-radius: 5%;
  padding: 4px;
}

.request-info-syn {
  display: flex;
  flex-direction: column;
  border-radius: 0.2em;
  align-items: center;
  justify-content: center;
  margin: 3em 10em 3em 10em;
  font-family: "Graphik";
  font-weight: bold;
}

.syn-request-info-title {
  color: var(--light-mode-white-text);
  border-radius: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
  width: 47em;
  background-color: var(--light-mode-syn-icon-color);
}
.request-info-child-syn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 1em;
}

.darkMode .syn-request-info-title {
  background-color: var(--dark-mode-syn-icon-color);
}

.darkMode .request-btn {
  background-color: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  color: var(--light-mode-white-text);
}

.darkMode .request-btn:hover {
  background-color: var(--dark-mode-home-btnbox-bg-color-hover);
  border: 2px solid var(--light-mode-btn-info-color);
}
.darkMode .save-btn {
  border-color: var(--dark-mode-text-color);
  color: var(--dark-mode-text-color);
}
.darkMode .save-btn:hover {
  background: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
}

.darkMode .remove-btn {
  color: var(--dark-mode-red-color-warning);
  border: 2px solid var(--dark-mode-red-color-warning);
}

.darkMode .remove-btn:hover {
  background-color: var(--dark-mode-red-color-warning);
  color: var(--dark-mode-dark-text);
}

.darkMode #pill:hover,
.darkMode #delete-pill:hover {
  background-color: var(--dark-mode-green-pill-hover);
}

.darkMode #pill:focus,
.darkMode #delete-pill:focus {
  box-shadow: 0 0 0 0.2rem var(--light-mode-pills-hover);
  border-color: var(--light-mode-pills-hover);
}
