/* import this file in each form along with all the components */

.form-main-div {
  margin-block: 0rem;
  padding-inline: 7rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  font-family: Graphik;
  padding-bottom: 120px;
  align-items: center;
}

.form-main-div .form-group {
  align-self: center;
  position: relative;
  margin-bottom: 5px;
}

.form-main-div .form-control {
  align-self: center;
  margin-bottom: 5px;
  width: calc(var(--bestbet-field-width));
}

.invalid-field-div {
  width: calc(var(--bestbet-field-width));
  color: var(--light-mode-invalid-feedback);
  font-size: 80%;
  margin-top: 0.25rem;
  text-align: center;
}

.invalid-field-div-hidden {
  display: none;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 70px);
  overflow-y: scroll;
}

.selected-location-or-refiners-div,
.selected-keywords-div,
.selected-contact-or-del-div,
.selected-delegates-div {
  display: grid;
  row-gap: 5px;
  justify-items: start;
  justify-content: start;
  min-width: 0px;
  max-width: 27em;
  grid-template-columns: auto auto fit-content(30%);
}

.location-refiner-and-keywords-main-div {
  margin-left: 2.5em;
}

.input-kw-add-btn,
.contact-or-del-add-btn,
.input-delegate-add-btn {
  fill: var(--purple_01);
}

.input-kw-add-btn:hover,
.contact-or-del-add-btn:hover,
.input-delegate-add-btn:hover {
  fill: var(--light-mode-green-01-color);
  cursor: pointer;
}

.kw-input-and-button-div, .contact-or-del-input-and-button-div {
  display: flex;
  align-items: center;
}

.plus-icon-css {
  margin-left: 1em;
}

.form-pills-style {
  margin-right: 1em;
  margin-top: 0.5em;
}

.form-kw-pills-extra{
  width: 11em;
}

.form-pills-btn-font-weigth {
  font-weight: 600;
}

.dropdown-field-div {
  margin-bottom: 0.7em;
}

.form-dropdown-styles {
  margin-bottom: 0.1em;
}

.hidden-delegate-div {
  width: 0em;
  height: 0em;
  display: none;
}

.adjust-kw-error-msg {
  position: relative;
  text-align: center;
  width: 37em;
}

.buttons-group {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-evenly;
  margin-top: 2em;
  max-width: -webkit-fill-available;
}

.buttons-group button {
  border-radius: 0.6em;
  font-size: 18px;
  font-weight: 700;
  height: 50px;
  min-width: 100px;
  width: 200px;
}

.form-buttons-div {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: -webkit-fill-available;
  justify-content: space-evenly;
  align-self: center;
  align-items: center;
  gap: 15px;
}

.form-buttons-main-div {
  position: relative;
  top: -8em;
}

.form-main-div button.btn-secondary:disabled {
  background-color: var(--light-mode-light-grey-color);
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
}

.form-main-div button.btn-secondary {
  background: #0000;
  border: 2px solid var(--light-mode-secondary-button-color);
  color: var(--light-mode-secondary-button-text-color);
}

.form-main-div button.btn-secondary:hover,
.form-main-div button.btn-secondary:focus {
  background-color: var(--light-mode-button-hover-color);
  color: var(--light-mode-white-color);
}

.btn:disabled {
  background: var(--light-mode-light-grey-color);
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
}

.btn-gray-class,
.btn-gray-class:focus,
.btn-gray-class:hover {
  background: var(--light-mode-light-grey-color);
  border: 2px solid var(--light-comment-tracker-date);
  color: var(--light-comment-tracker-date);
  cursor: default;
  font-weight: 700;
  opacity: 0.65;
}

.owner-css-save-changes-btn {
  background: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  color: #ffffff;
}

.owner-css-save-changes-btn:disabled {
  border: 2px solid var(--light-mode-light-grey-color-2);
  color: var(--light-mode-light-grey-color-2);
  background-color: var(--light-mode-light-grey-color);
}

.owner-css-save-changes-btn:hover {
  background: var(--light-mode-button-outline-base-color);
  color: white;
  border: 2px solid var(--light-mode-button-outline-base-color);
}

.owner-css-save-changes-btn:active {
  background: transparent;
  border: 2px solid var(--light-mode-secondary-button-color);
  color: var(--light-mode-secondary-button-text-color);
  font-size: 18px;
  font-weight: bold;
}

.red-form-btn:hover {
  color: var(--light-mode-white-color);
}

.red-form-btn:active {
  color: var(--light-mode-white-color) !important;
}

.wrap-text-table-row {
  overflow-wrap: anywhere;
}

.table-holder:focus-visible {
  outline: none;
}

.table-bordered > :not(caption) > * > * {
  border-width: 2px 2px;
}
.table-bordered {
  table-layout: fixed;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.table {
  --bs-table-border-color: var(--light-mode-text-color);
}

.req-comparison-tables-div {
  background: var(--light-mode-white-color);
  position: sticky;
  top: 0em;
  padding: 1em;
  border-radius: 0.3em;
}

.req-comparison-tables-title {
  background-color: var(--light-mode-white-color);
  color: var(--light-mode-link-color-normal);
  font-weight: 600;
}

.req-table-fields-col {
  font-weight: 600;
  color: var(--light-mode-text-color);
  text-decoration: underline;
  font-size: 1.1em;
}

.req-table-before-col {
  font-weight: 600;
  color: var(--light-mode-red-color-warning);
  text-decoration: underline;
  font-size: 1.1em;
}

.req-table-after-col {
  font-weight: 600;
  color: var(--light-mode-title-color-blue);
  text-decoration: underline;
  font-size: 1.1em;
}

.request-info-parent-div {
  padding-left: 4em;
  padding-right: 4em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1em;
}

.request-info-title-div {
  align-items: center;
  border-radius: 0.2em;
  color: var(--light-mode-white-text);
  display: flex;
  height: 3em;
  justify-content: center;
  column-gap: 15px;
}

/* add the header color for each from */
.BB-info-title-div.request-info-title-div {
  background-color: var(--light-mode-bb-icon-color);
  border: 1px solid var(--light-mode-border-header-bb);
}
/* ex : check each form's css file to see the correct values 
.TC-info-title-div.request-info-title-div{
    background-color: var(tc color);
    border: 1px solid var(tc color);
} */
/* ----- */

.request-info-child-div {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
  column-gap: 10px;
}

.request-info-block {
  color: var(--light-mode-feedback-purple);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px;
}

.update-info {
  color: var(--light-mode-white-text);
  border-radius: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  column-count: 2;
  column-gap: 60px;
  margin: 0 auto;
  padding-left: 30px;
  text-align: center;
  min-height: 3em;
  padding-right: 30px;
}

.BB-info.update-info {
  background-color: var(--light-mode-bb-icon-color);
  border: 1px solid var(--light-mode-border-header-bb);
  width: var(--bestbet-field-width);
}

.TC-info.update-info {
  background-color: var(--light-mode-tc-icon-color);
  border: 1px solid var(--light-mode-tc-icon-color);
  width: var(--bestbet-field-width);
}

.loading-spinner-main-div {
  position: relative;
  top: 12em;
  z-index: 14;
}

.change-kw-spinner-size {
  height: 0px;
}

#url-suggestions-list {
  padding: 0;
  margin: 0;
  border: 1px solid var(--light-mode-light-grey-color);
  max-height: 155px;
  overflow: auto;
  width: calc(var(--bestbet-field-width) - 3px);
  text-align: left;
  align-self: center;
  z-index: 1;
  overflow-x: hidden;
  position: relative;
  top: -0.3em;
}

.url-suggestion-list-class {
  padding: 3px 5px 3px 12px;
  background-color: var(--light-mode-white-color);
  color: var(--light-mode-spinner-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.url-suggestion-list-class:hover {
  background-color: var(--light-mode-autocomplete-results-bg-color);
  color: var(--light-mode-white-color);
}

.toast-body {
  font-family: Graphik;
}

.kw-synonyms-list {
  background-color: var(--light-mode-light-grey-color-3);
  display: flex;
  flex-flow: wrap;
  height: auto;
  margin-bottom: 15px;
  margin-top: 3px;
  padding: 15px;
}

.spinner-synonyms {
  position: relative;
  top: 5px;
  width: 1rem !important;
  height: 1rem !important;
}

.kw-and-comments-div {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}


.TC-contact-and-del-field-extra-margin{
  margin-left: 2.4em;
  margin-top: 0.5em;
}

.TC-contact-and-del-field-extra-margin-2{
  margin-left: 2.45em;
  margin-top: 0.5em;
}

.line-items-modal-extra-class{
  top: -0.5em;
  width: 45em;
  left: 5em;
}

.line-items-modal-body-div{
  overflow: scroll;
  max-height: 30em;
}

.line-items-btn-ok-class{
  border-radius: 8px !important;
}

.line-items-btn-ok-class:hover, 
.line-items-btn-ok-class:active, 
.line-items-btn-ok-class:focus{
  background: var(--dark-mode-serach-bar-color) !important;
}



@media (min-width: 1024px) and (max-width: 1024px) {
  .comment-tracker-adjust-max-width{
    max-width: 26.4em;
  }
}

@media (min-width: 1280px) and (max-width: 1370px) {
  .comment-tracker-adjust-max-width{
    max-width: 32.7em;
  }
}

@media (min-width: 1440px) and (max-width: 1440px) {
  .comment-tracker-adjust-max-width{
    max-width: 36.3em;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {
  .comment-tracker-adjust-max-width{
    max-width: 60.3em;
  }
}

.new-minus-plus-line-item-icon-class{
    margin-left: 7px;
    cursor: pointer;
    margin-bottom: 20px;
    color: var(--light-mode-icon-color);
    opacity: 1;
}


.line-item-buttons-extra{
  margin-left: 25px !important;
  height: 3em;
}

.line-item-buttons-extra-case-error{
  position: relative;
  top: -1.5em;
}


.line-item-item-error-msg-div{
  width: 16em !important;
  position: relative;
  left: 17.2px;
}

.tc-comments-main-div{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.tc-comments-fix-width{
  width: 100%;
}


.line-item-url-refactor {
  flex: 0 0 auto;
  width: 33%;
}

.tc-line-items-pos-div{
  position: relative;
  left: 6.5em;
}

.tc-line-items-div{
  position: relative;
  left: 1.5em;
}


.line-item-title-new{
  margin-right: 30px;
  flex: 0 0 auto;
}

.line-item-input-width{
  width: 15em !important;
}

.TC-url-field-extra-margin{
 margin-left: 1.5em;
}


.TC-line-items-main-div-class{
  width: 33em;
}

.TC-line-items-main-btn{
  width: 100%;
  height: 3.3em;
}



/* DARK MODE SECTION */

.darkMode .invalid-field-div {
  color: var(--dark-mode-red-01-color);
}

.darkMode .request-info-title-div,
.darkMode .update-info {
  color: var(--dark-mode-disclaimers-font-bb);
  background-color: var(--dark-mode-bb-icon-color);
}

.darkMode .req-table-after-col {
  color: var(--dark-mode-title-color-blue);
}

.darkMode .req-table-fields-col {
  color: var(--dark-mode-text-color);
}

.darkMode .req-comparison-tables-title {
  background-color: var(--dark-mode-modal-background-color);
  color: var(--dark-mode-link-color-normal);
}

.darkMode .req-comparison-tables-div {
  background-color: var(--dark-mode-modal-background-color);
}

.darkMode .owner-css-save-changes-btn:hover {
  background-color: var(--dark-mode-btn-primary-color-hover);
}

.form-tooltip-dark {
  background-color: var(--dark-mode-modal-border);
  border-radius: 5%;
  padding: 4px;
}
.darkMode button#BB-save-draft-button {
  background: var(--light-mode-btn-info-color);
  border: 2px solid var(--light-mode-btn-info-color);
  color: var(--dark-mode-text-color);
}
.darkMode button#BB-save-draft-button:disabled {
  background: var(--dark-mode-secondary-button-bg-color);
  border: 2px var(--dark-mode-btn-secondary-disabled-border-color);
  color: var(--dark-mode-btn-secondary-disabled-color);
}

.darkMode .form-buttons-div .btn-outline-danger {
  background: #0000;
  border: 2px solid var(--dark-mode-red-color-warning);
  color: var(--dark-mode-red-color-warning);
}

.darkMode .form-buttons-div button.btn-secondary {
  border: 2px solid var(--dark-mode-secondary-button-border-color);
  color: var(--dark-mode-base-btn-text-color);
}

.darkMode .form-buttons-div button.btn-secondary:disabled {
  background: var(--dark-mode-secondary-button-bg-color);
  border: 2px var(--dark-mode-btn-secondary-disabled-border-color);
  color: var(--dark-mode-btn-secondary-disabled-color);
}

.darkMode .form-buttons-div .btn-outline-danger:hover {
  background: var(--dark-mode-red-color-warning);
  border: 2px solid var(--dark-mode-red-color-warning);
  color: var(--dark-mode-dark-text);
}

.darkMode .form-buttons-div button.btn-secondary:hover {
  background-color: var(--dark-mode-secondary-button-color);
  border: 2px solid var(--light-mode-button-hover-color);
  color: var(--light-mode-white-color);
}

.darkMode .form-main-div .btn.btn-success:hover {
  background-color: var(--light-mode-green-03-color);
  cursor: pointer;
}

.darkMode #url-suggestions-list {
  border: 1px solid var(--dark-mode-border-color);
}

.darkMode .url-suggestion-list-class {
  background-color: var(--dark-mode-landing-bg-color);
  color: var(--dark-mode-text-color);
}

.darkMode .url-suggestion-list-class:hover {
  background-color: var(--dark-mode-link-color-hover);
  color: var(--light-mode-white-color);
}
