.ctc-preview-title {
  color: var(--light-mode-title-color-purple);
  font-size: 1.5em;
  font-weight: 500;
}
.ctc-preview-title.is-url:hover {
  color: var(--light-mode-title-color-purple);
  text-decoration: underline var(--light-mode-title-color-purple);
}

/* dark mode */
.darkMode #ctc-preview-description > table > tbody > tr > td {
  border: 1px solid var(--dark-mode-text-color) !important;
}

.UpperBodyDiv table td {
  padding-right: 0px;
  line-height: 24px !important;
}

.darkMode .UpperBody tr:hover {
  background-color: #a100ff;
}
.darkMode #CTC_Title_ID {
  font-size: 26px;
  font-weight: 700;
  color: var(--dark-mode-title-color-purple);
}
.darkMode .editor-link {
  color: var(--dark-mode-link-color-normal);
  text-decoration: none;
}
